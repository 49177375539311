import React from "react";
import { Link } from "react-router-dom";
import { Broadcast } from "../utils/type";
import { prettifyContent, sample_image_url } from "../utils/content.ts";

interface BroadcastsProps {
    broadcast: Broadcast;
}

export default function BroadcastEntry({ broadcast }: BroadcastsProps) {
    return (
        <Link to={`/broadcasts/topics/${broadcast.id}`} className="flex flex-row p-2 bg-base-100">
            {sample_image_url(broadcast) && <img className="h-24 w-24 object-cover rounded" src={sample_image_url(broadcast)} alt="avatar" />}
            <div className="flex flex-col items-start p-2 gap-1" >
                <h2>{broadcast.title}</h2>
                <p className="line-clamp-2 h-8 text-xs text-slate-500">{!broadcast.hide_content ? prettifyContent(broadcast): ""}</p>
                <p className="line-clamp-1 text-xs text-slate-500 text-xs">{broadcast.character_name} - {broadcast.public?`${broadcast.count}次使用`: "私密"}</p>
            </div>
        </Link>
    )
}