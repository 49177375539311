import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import Cookies from "js-cookie"
import React from "react"
import NavbarContainer from "../NavbarContainer.tsx"
import { Link } from "react-router-dom"
import UserAvatar from "../common/UserAvatar.tsx"
import { dateToText } from "../utils/date.js"

const NotificationEntry = ({ comment }) => {

    const getLink = () => {
        if (comment["content_id"]) {
            if (!comment["content_character_id"]) {
                return `/content/it/${comment["content_id"]}`
            }
            return `/content/${comment["content_id"]}`
        }
        return `/character/${comment["character_id"]}`
    }

    const getText = () => {
        if (comment["parent_comment_id"]) {
            return "回复了我的评论"
        }
        if (comment["content_id"]) {
            return  "评论了我的分享"
        }
        return  "评论了我的角色"
    }

    return (
        <Link className="flex flex-row gap-2 items-start p-2 my-1 bg-base-100 justify-between items-center" to={getLink()}>
            <div className="flex flex-row gap-2">
                <Link className="items-center p-2" to={`/profile/${comment["user_id"]}`}>
                    <UserAvatar user={comment} size={8} />
                </Link>

                <div className="flex flex-col">
                    <p><b>{comment["nickname"]}</b> {getText()}</p>
                    <p className="break-all line-clamp-1">{comment["comment"]}</p>
                    <div className="text-sm text-slate-400">{dateToText(new Date(comment["time_created"] + "Z").getTime())}</div>
                </div>
            </div>

            <i className="fa-solid fa-chevron-right p-2"/>

        </Link>
    )
}

const NotificationPage = () => {

    const [comments, setComments] = useState([])

    useEffect(()=>{
        fetchComments()
    }, [])

    const fetchComments = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/user/notification/comment`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json()
            setComments(data["comments"])
            if (data["comments"] && data["comments"].length > 0) {
                localStorage.setItem('latest_related_comment_ts', data["comments"][0]["time_created"])
            }
        } catch (error) {
            toast.error("获取失败")
        }
    }


    return (
        <NavbarContainer title="我的消息">
            {
                comments.map((comment)=>(
                    <NotificationEntry comment={comment} />
                ))
            }
        </NavbarContainer>
    )
}

export default NotificationPage