import React, { createContext, useContext, useState } from 'react';
import { Broadcast, Character } from '../utils/type.ts';
import { useUser } from './UserContext.tsx';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';

interface CharacterContextType {
  publicCharacters: Character[]
  fetchPublicCharacterAsync: () => Promise<void>;
  linkedCharacters: Character[]
  fetchLinkedCharacterAsync: () => Promise<void>;
  myCharacters: Character[]
  fetchMyCharacterAsync: () => Promise<void>;
  publicTopics: Broadcast[]
  fetchPublicTopicsAsync: () => Promise<void>;
  groups: any[]
  fetchGroupsAsync: () => Promise<void>;
  loading: boolean;
}

// Define a default value for the context
const defaultCharacterContextValue: CharacterContextType = {
  publicCharacters: [],
  linkedCharacters: [],
  myCharacters: [],
  publicTopics: [],
  groups: [],
  fetchPublicCharacterAsync: async () => { },
  fetchLinkedCharacterAsync: async () => { },
  fetchMyCharacterAsync: async () => { },
  fetchPublicTopicsAsync: async () => { },
  fetchGroupsAsync:  async () => { },
  loading: true,
};

const CharacterContext = createContext<CharacterContextType>(defaultCharacterContextValue);

export function useCharacter() {
  return useContext(CharacterContext);
}

export const CharacterProvider = ({ children }) => {

  const [linkedCharacters, setLinkedCharacter] = useState<Character[]>([])
  const [myCharacters, setMyCharacters] = useState<Character[]>([])
  const [publicCharacters, setPublicCharacters] = useState<Character[]>([])
  const [publicTopics, setPublicTopics] = useState<Broadcast[]>([])
  const [groups, setGroups] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useUser()

  const fetchGroupsAsync = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/groupchat/list`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setGroups(data.group_chats);
        setLoading(false);
    } catch (error) {
        setLoading(false);
    }
};

  const fetchPublicCharacterAsync = async () => {
    setLoading(true)
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
        },
        body: JSON.stringify({
          "sorting": "combined",
          "page_size": 100,
        })
      });

      if (res.status !== 200) {
        toast.error("获取角色失败🙅");
        setLoading(false)
        return
      }

      const data = await res.json();
      setPublicCharacters(
        data["characters"]
      )
      setLoading(false)
      return
    } catch (e) {
      toast.error("获取角色失败🙅");
      setLoading(false)
      return
    }
  }

  const fetchLinkedCharacterAsync = async () => {
    if (!user) {
      return
    }
    setLoading(true)
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
        },
        body: JSON.stringify({
          "chatted_with_user_id": user.id,
          "page_size": 100,
        })
      });

      if (res.status !== 200) {
        toast.error("获取角色失败🙅");
        setLoading(false)
        return
      }

      const data = await res.json();
      setLinkedCharacter(
        data["characters"]
      )
      setLoading(false)
      return
    } catch (e) {
      toast.error("获取角色失败🙅");
      setLoading(false)
      return
    }
  }

  const fetchMyCharacterAsync = async () => {
    if (!user) {
      return
    }
    setLoading(true)
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
        },
        body: JSON.stringify({
          "user_id": user.id,
          "sorting": "latest",
          "page_size": 100,
        })
      });

      if (res.status !== 200) {
        toast.error("获取角色失败🙅");
        setLoading(false)
        return
      }

      const data = await res.json();
      setMyCharacters(
        data["characters"]
      )
      setLoading(false)
      return
    } catch (e) {
      toast.error("获取角色失败🙅");
      setLoading(false)
      return
    }
  }

  const fetchPublicTopicsAsync = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/broadcast/topics?page=1&page_size=20`, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
        },
      });
      const data = await res.json();
      setPublicTopics(data["topics"])
    } catch (error) {
      toast.error("获取话题失败🙅");
      return
    }
  }

  return (
    <CharacterContext.Provider value={{
      loading, publicCharacters, fetchPublicCharacterAsync,
      linkedCharacters, fetchLinkedCharacterAsync,
      myCharacters, fetchMyCharacterAsync,
      publicTopics, fetchPublicTopicsAsync,
      groups, fetchGroupsAsync,
    }}
    >
      {children}
    </CharacterContext.Provider>
  );
};