// ChatHistoryEditor.tsx
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactTextareaAutosize from 'react-textarea-autosize';
import NavbarContainer from '../NavbarContainer.tsx';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import Markdown from '../common/markdown.tsx';
import QuestionEntry from '../common/QuestionEntry.tsx';
import { ContentItemBody } from './ContentBrowse.tsx';
import { Character } from '../utils/type.ts';
import { fetchCharacterAPI } from '../utils/api.ts';
import { replaceSensitiveWords } from '../utils/censor/censor.ts';
import BackgroundSelection from '../common/BackgroundSelection.tsx';
import { useUser } from '../context/UserContext.tsx';

interface ChatEntry {
    id: number;
    name: string;
    dialog: string;
}

 export interface CreateContentProps {
    value: string;
    character_id: string;
    content_id: number | null
    title_value: string;
    description_value: string;
    cover_value: string | null;
    bgm_value: string | null;
    character_options: any[]
}

export const defaultProps: CreateContentProps = {
    value: "",
    character_id: "",
    content_id: null,
    title_value: "",
    description_value: "",
    cover_value: "",
    bgm_value: "",
    character_options: []
}

// Function to convert a string back to chat history and assign new IDs
const convertStringToChatHistory = (historyString: string) => {
    try {
        const entriesWithoutIds = JSON.parse(historyString);
        return entriesWithoutIds.map((entry, index) => ({
            ...entry,
            id: index + 1  // Assigning new IDs starting from 1
        }));
    } catch (error) {
        console.error('Failed to parse chat history string:', error);
        return []; // Return an empty array in case of error
    }
};

const getUniqueNames = (chats: ChatEntry[]): string[] => {
    const nameSet = new Set<string>();
    chats.forEach(chat => nameSet.add(chat.name));
    return Array.from(nameSet);
};

function InsertImageButton({charID, insert}) {

    const ref = useRef<HTMLDialogElement>(null)
    const [selected, setSelected] = useState("")

    const onConfirm = ()=> {
        insert(selected)
        ref.current?.close()
    }

    return (
        <>
            <button
                onClick={() => ref.current?.showModal()}
                className="btn btn-primary btn-sm"
            >
                插入图片
            </button>
            <dialog className="modal" ref={ref}>
                <div className="modal-box flex flex-col gap-2">
                    <h3 className="font-bold text-lg">选择图片</h3>
                    <BackgroundSelection character_id={charID} selected={selected} setSelected={setSelected} />
                    <button className="btn btn-sm" onClick={onConfirm}>确定</button>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </>
    );
}

const DraggableEntry = ({ entry, index, setCover, editEntry, deleteEntry, charID }) => {
    const [isPreview, setIsPreview] = useState(true);

    const hasSensitiveWords = () => {
        return replaceSensitiveWords(entry.dialog) != entry.dialog
    }
    return (
        <Draggable draggableId={entry.id.toString()} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="flex flex-col p-3 my-2 gap-2 bg-base-200 rounded-lg"
                >
                    <span className="font-semibold">{entry.name}:</span>
                    {isPreview ? (
                        <div className="flex flex-col">
                            <Markdown>{entry.dialog}</Markdown>
                        </div>
                    ) : (
                        <ReactTextareaAutosize
                            value={entry.dialog}
                            onChange={(e) => editEntry(entry.id, e.target.value)}
                            className="textarea textarea-bordered w-full mx-2"
                        />
                    )}
                    {hasSensitiveWords() && <span className="text-error">请修改违规内容</span>}
                    <div className="flex flex-row gap-2">
                        <button
                            onClick={() => setIsPreview(!isPreview)}
                            className="btn btn-primary btn-sm"
                        >
                            {isPreview ? '编辑' : '预览'}
                        </button>
                        <InsertImageButton charID={charID} insert={(url) => editEntry(entry.id, `![](${url})\n${entry.dialog}`)} />
                        <button
                            onClick={() => setCover(entry.dialog)}
                            className="btn btn-primary btn-sm"
                        >
                            设为封面
                        </button>
                        <button
                            onClick={() => deleteEntry(entry.id)}
                            className="btn btn-error btn-sm"
                        >
                            删除
                        </button>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

const questions = [
    {
      id: 'description',
      label: '想说的话',
      placeHolder: '分享你最爱的TA',
      maxLen: '150',
      textArea: true,
    },
]

const CreateContentPage = () => {

    const { state } = useLocation();
    const {user} = useUser()
    const { value, character_id, content_id, title_value, description_value, cover_value, bgm_value, character_options } = state ? state as CreateContentProps : defaultProps;


    const [chatHistory, setChatHistory] = useState<ChatEntry[]>(convertStringToChatHistory(value));
    const userOptions = getUniqueNames(convertStringToChatHistory(value)); // Options for the name dropdown
    const [newEntryText, setNewEntryText] = useState("");
    const [selectedName, setSelectedName] = useState(userOptions[0]); // Default to the first user
    const [choices, setChoices] = useState({
        "title": title_value || "",
        "description": description_value || "",
        "cover": cover_value || "",
        "bgm": bgm_value || "",
    })
    const [submitting, setSubmitting] = useState(false);
    const [characterID, setCharacterID] = useState(character_id)
    const [character, setCharacter] = useState<Character | null>(null)

    const fetchCharacterAsync = async () => {
        try {
            const character = await fetchCharacterAPI(characterID)
            setCharacter(character)
        } catch (error) {
            toast.error("参数错误")
            return
        }
    }

    useEffect(()=>{
        fetchCharacterAsync()
    }, [characterID])

    const navigate = useNavigate()

    // Function to handle drag and drop
    const onDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(chatHistory);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setChatHistory(items);
    };

    // Add new entry
    const addEntry = () => {
        const newEntry = {
            id: Math.max(0, ...chatHistory.map(entry => entry.id)) + 1, // Generate a new unique ID
            name: selectedName,
            dialog: newEntryText
        };
        setChatHistory([...chatHistory, newEntry]);
        setNewEntryText(''); // Reset input field
    };

    // Edit a chat entry
    const editEntry = (id: number, newDialog: string) => {
        setChatHistory(chatHistory.map((entry) => (entry.id === id ? { ...entry, dialog: newDialog } : entry)));
    };

    // Delete a chat entry
    const deleteEntry = (id: number) => {
        setChatHistory(chatHistory.filter((entry) => entry.id !== id));
    };

    const getChatHistoryString = () => {
        const historyWithoutIds = chatHistory.map(({ name, dialog }) => ({ name, dialog }));
        return JSON.stringify(historyWithoutIds, null, 2); // Beautify the JSON output
    };

    const handleSubmit = async () => {
        if (submitting) return
        setSubmitting(true)
        const content = getChatHistoryString()
        const replacedContent = replaceSensitiveWords(content)
        if (replacedContent != content) {
            toast("存在违规内容")
            setSubmitting(false)
            return
        }
        if (chatHistory.length < 2) {
            toast("分享内容过短！")
            setSubmitting(false)
            return
        }
        if (chatHistory.length > 25)  {
            toast("分享内容过长！(>25条)")
            setSubmitting(false)
            return
        }
        const res = await fetch(`${process.env.REACT_APP_API_URL}/content`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
            },
            body: JSON.stringify({
                "id": content_id,
                "content": content,
                "character_id": characterID,
                "title": choices["title"],
                "description": choices["description"],
                "cover": choices["cover"],
                "bgm": choices["bgm"],
            })
        });

        if (res.status !== 200) {
            toast.error("发布失败🙅");
            setSubmitting(false)
            return
        }

        const data = await res.json();
        const id = data["id"]
        navigate(`/content/${id}`, {replace: true})
    }

    if (!character_id) {
        return <p className="text-center p-10 text-lg">页面已失效</p>
    }

    const characterSelection = () => (
        <div className="flex flex-col gap-2 m-4">
            <p>主要角色</p>
            <select className="select max-w-md w-full"
                value={characterID}
                onChange={(e) => setCharacterID(e.target.value)}>
                {character_options.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                ))}
            </select>
        </div>

    )

    const getItem = () => {
        return {
            portrait_url: character?.portrait_url,
            cover: choices["cover"],
            content: JSON.stringify(chatHistory)
        }
    }

    return (
        <NavbarContainer title="分享对话">
            <div className="flex flex-col gap-2 m-4">
                { character_options && character_options.length > 0 && characterSelection()}
                <div className="flex flex-col">
                    <div className="label">封面预览</div>
                    {character && <ContentItemBody item={getItem()} isTextImage={false} /> } 
                </div>
                {
                    questions.map((question, index)=>(
                        <QuestionEntry key={index} question={question} choices={choices} setChoices={setChoices}/>
                    ))
                }
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="chatEntries">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {chatHistory.map((entry, index) => (
                                    <DraggableEntry key={entry.id}
                                        charID={characterID} entry={entry} index={index}
                                        setCover={(cover)=>setChoices({...choices, cover: cover})} editEntry={editEntry} deleteEntry={deleteEntry}
                                    />
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className="flex mt-4">
                    <select
                        className="select select-bordered flex-none"
                        value={selectedName}
                        onChange={(e) => setSelectedName(e.target.value)}
                    >
                        {userOptions.map((name) => (
                            <option key={name} value={name}>{name}</option>
                        ))}
                    </select>
                    <ReactTextareaAutosize
                        value={newEntryText}
                        onChange={(e) => setNewEntryText(e.target.value)}
                        placeholder="添加对话"
                        className="textarea textarea-bordered w-full mx-2"
                    />
                    <button onClick={addEntry} className="btn btn-primary">
                        添加
                    </button>
                </div>
                <button className="btn btn-secondary mt-8 max-w-md self-center w-full" onClick={handleSubmit} disabled={!character_id || chatHistory.length === 0 || submitting}>发布</button>
            </div>
        </NavbarContainer>
    );
};

export default CreateContentPage;
