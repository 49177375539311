import React, { useEffect, useState } from "react";
import NavbarContainer from "../NavbarContainer.tsx";
import { useParams } from "react-router-dom";
import { useUser } from "../context/UserContext.tsx";
import toast from "react-hot-toast";
import QuestionEntry from "../common/QuestionEntry.tsx";
import Cookies from "js-cookie";

const questions = [
    {
      id: 'content',
      label: '设定内容',
      placeHolder: '关于你的信息，或者是任何你想补充的设定',
      maxLen: '300',
      textArea: true,
    },
]

export default function CharacterSettingPage() {

    const {charID} = useParams()

    const [setting, setSetting] = useState<any>({})
    const [submitting, setSubmitting] = useState(false)

    const fetchSetting = async () => {
        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/${charID}/setting`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
            }
          });
      
          if (res.status !== 200) {
            toast.error("获取失败🙅");
            return
          }
      
          const data = await res.json();
          return setSetting(data)
        } catch (e) {
          toast.error("获取失败🙅");
          return
        }
    }

    const submitSetting = async () => {
        if (submitting) {
            return
        }
        setSubmitting(true)
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/${charID}/setting`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
              },
              body: JSON.stringify(setting)
            });
        
            if (res.status !== 200) {
              toast.error("更新失败🙅");
            }
            toast.success("保存成功");
        } catch (e) {
            toast.error("更新失败🙅");
        } finally {
            setSubmitting(false)
        }
    }

    useEffect(()=>{
        fetchSetting()
    }, [])

    return (
        <NavbarContainer title="主控设定">
            <div className="flex flex-col p-3 gap-4">
                {
                    questions.map((question, index)=>(
                        <QuestionEntry key={index} question={question} choices={setting} setChoices={setSetting}/>
                    ))
                }
                <button
                    className="btn btn-primary mt-4 max-w-md w-full"
                    disabled={submitting}
                    onClick={submitSetting}
                >
                    保存
                </button>
            </div>
        </NavbarContainer>
    )
}