import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import NavbarContainer from '../NavbarContainer.tsx';

interface Content {
  id: string;
  likes_mod: number;
}

const ContentModPage: React.FC = () => {
  const { content_id } = useParams<{ content_id: string }>();
  const [content, setContent] = useState<Content | null>(null);
  const [likesMod, setLikesMod] = useState<number>(0);

  useEffect(() => {
    if (content_id) {
      fetchContent(content_id);
    }
  }, [content_id]);

  const fetchContent = async (id: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/content/${id}`);
      if (!response.ok) {
        toast.error('response was not ok');
      }
      const data: Content = await response.json();
      setContent(data);
      setLikesMod(data.likes_mod);
    } catch (error) {
      console.error('Error fetching content:', error);
    }
  };

  const updateLikesMod = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/content/${content_id}/mod`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
        },
        body: JSON.stringify({ mod: likesMod }),
      });
      if (!response.ok) {
        toast.error('response was not ok');
      }
      toast.success('Likes mod updated successfully!');
    } catch (error) {
      console.error('Error updating likes mod:', error);
    }
  };

  return (
    <NavbarContainer>
      <h1 className="text-xl font-bold mb-4">Update Likes Mod</h1>
      <div className="mb-4">
        <label htmlFor="likesMod" className="block text-sm font-medium text-gray-700">
          Likes Mod:
        </label>
        <input
          type="number"
          id="likesMod"
          value={likesMod}
          onChange={(e) => setLikesMod(parseInt(e.target.value))}
          className="mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      {content && (
        <div className="mb-4">
          <p>Content ID: {content.id}</p>
          <p>Current Likes Mod: {content.likes_mod}</p>
        </div>
      )}
      <button
        onClick={updateLikesMod}
        className="px-4 py-2 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Update Likes Mod
      </button>
    </NavbarContainer>
  );
};

export default ContentModPage;
