import React, {useState, useEffect, useRef} from "react";
import { updateInfoAPI, uploadResource } from '../utils/api.ts';
import { useUser } from "../context/UserContext.tsx";
import { Link } from "react-router-dom";
import ContentBrowse from "../content/ContentBrowse.tsx";
import CharacterBrowse from "../character/CharacterBrowse.tsx";
import UserAvatar from "../common/UserAvatar.tsx";
import AvatarCropper from "../AvatarCropper.tsx";
import toast from "react-hot-toast";

const tabs = [
    { name: '角色' },
    { name: '分享' },
];

function MyCharacters({user_id}) {
    return (
        <CharacterBrowse sorting="combined" prefix={`character-browse-combined-self-${user_id}`} authed={true} user_id={user_id}/>
    )
}

/*
function ReplyMe() {
    const [replyList, setReplyList] = useState([])

    const fetchReplyList = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/user/comment/replyme`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json();
            const replies = data["replies"]

            setReplyList(replies)
        } catch (error) {
            console.log(error)
        }
    }

    const markRead = async (comment_extension_id) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/user/comment/read?comment_extension_id=${comment_extension_id}`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json();
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        fetchReplyList()
    }, [])

    return (
        <div className="flex flex-col">
            {replyList.map((reply :any, index) => (
                <Link to={`/broadcasts/topics/${reply.broadcast_id}`} className="bg-base-100 flex flex-row p-2 border-b-[1px] border-slate-200"  onClick={() => !reply.is_read && markRead(reply.id)}>
                    <div className="flex flex-col p-3">
                        <div className="flex flex-row space-x-2 truncate justify-center items-center">
                            {!reply.is_read && <div className="badge badge-primary badge-xs"></div>}
                            <p className="text-secondary">{reply.author_username}</p>
                            <p>回复了我的评论</p>
                            <p className="text-gray-600">{reply.time_created.split('T')[0]}</p>
                        </div>
                        <div className="line-clamp-1 text-slate-500">
                            {reply.content}
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    )
}*/

export default function ProfilePage() {

    const {user, setUser, fetchUserAsync} = useUser()

    const [nickname, setNickname] = useState("")
    const [loading, setLoading] = useState(false)

    const updateNicknameDialogRef = useRef<HTMLDialogElement | null>(null);

    useEffect(()=>{
        fetchUserAsync()
    }, [])

    useEffect(()=>{
        setNickname(user?.nickname || "")
    }, [user?.nickname])

    const handleSave = async (avatar="") => {
        if (loading) {
            return
        }
        setLoading(true)
        const res = await updateInfoAPI(nickname, avatar)
        if (res !== null) {
            setUser(res)
        }
        setLoading(false)
    }

    function showUpdateNicknameDialog(e) {
        if (updateNicknameDialogRef && updateNicknameDialogRef.current) updateNicknameDialogRef.current.showModal()
    }

    const navbar = () => (
        <div className="navbar fixed top-0 left-0 w-full z-[99] bg-base-100">
            <div className="flex-1"></div>
            <div className="navbar-end">
                <Link to="/setting" className="btn btn-ghost">
                    <i className="fa-solid fa-gear" />
                </Link>
            </div>
        </div>
    )

    const updateNicknameDialog = () => {
        return (
            <dialog ref={updateNicknameDialogRef} className="modal">
                <div className="modal-box space-y-4">
                    <h3 className="font-bold text-lg">修改昵称</h3>
                    <div className="flex flex-col space-y-2" >
                        <input type="text" value={nickname}
                            onChange={e=>setNickname(e.target.value)} className="input input-bordered w-full max-w-md" 
                        />
                    </div>
                    <div className="modal-action">
                        <form method="dialog" className="space-x-4">
                            <button className="btn" onClick={()=>handleSave()} >保存</button>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        )
    }

    const updateAvatar = async (avatar: string) => {
        // upload Avatar
        if (avatar.startsWith("data")) {
            setShowAvatarCropper(false)
            toast("请稍等...")
            let blob = await (await fetch(avatar)).blob();
            let fileName = "avatar.png";
            let file = new File([blob], fileName, { type: blob.type });
            const avatarURL = await uploadResource(file, ()=> {})
            if (avatarURL) {
                await handleSave(avatarURL)
            }
        }
    }

    const [activeTab, setActiveTab] = useState(tabs[0].name);   
    const [showAvatarCropper, setShowAvatarCropper] = useState(false) 

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const infoBox = (title: string, value: number | undefined, url: string) => (
        <Link to={url} className="bg-base-100 w-full p-4 max-w-xs rounded flex flex-row items-center">
            <div className="w-full">
                {title}
                <div className="flex flex-row items-center gap-2">
                    <i className="fa-solid fa-coins" />
                    {value}
                </div>
            </div>
            {url && <i className="fa-solid fa-chevron-right" />}
        </Link>
    )

    return (
        <div className="h-full overflow-y-auto no-scrollbar">
            <div className="pb-32 pt-[64px] ">
                {navbar()}
                <div className="flex flex-col px-3">
                    {updateNicknameDialog()}
                    <div className="card card-side">
                        <div className="card-body">
                            <div className="flex flex-row space-x-2 items-center">
                                {user?.username && <div onClick={()=>setShowAvatarCropper(true)}><UserAvatar user={user} size={16} enableZoom={false}/></div>}
                                <h2 className="card-title text-xl">{nickname}</h2>
                                <button className="btn btn-sm rounded-full" onClick={showUpdateNicknameDialog}><i className="far fa-edit"></i></button>
                                {showAvatarCropper && <AvatarCropper 
                                    src={""}
                                    setShow={setShowAvatarCropper}
                                    onConfirm={updateAvatar}
                                />} 
                            </div>
                        </div>
                    </div>
                    <div className="p-4 text-sm">
                        我的钱包
                    </div>
                    <div className="flex gap-4">
                        {infoBox("余额", user?.coin, "/me/coin")}
                        {infoBox("创作收益", user?.inspiration_coin, "/withdraw")}
                    </div>
                    <div role="tablist" className="tabs tabs-bordered py-4">
                        {tabs.map((tab) => (
                            <a
                                key={tab.name}
                                role="tab"
                                className={`tab ${activeTab === tab.name ? 'tab-active' : ''}`}
                                onClick={() => handleTabClick(tab.name)}
                            >
                                {tab.name}
                            </a>
                        ))}
                    </div>
                    {activeTab === tabs[0].name && <MyCharacters user_id={user?.id} />}
                    {activeTab === tabs[1].name && <ContentBrowse user_id={user?.id} prefix="my-content-browse" character_id={undefined} />}
                </div>
            </div>
        </div>
    )
}