import React, { useEffect, useRef, useState } from 'react';
import CharacterBrowse from './CharacterBrowse.tsx';
import { Link } from 'react-router-dom';
import ImageWithSkeleton from '../common/ImageWithSkeleton.tsx';

const tabs = [
    { name: '推荐' },
    { name: '最新' },
    { name: '男性' },
    { name: '女性' },
    { name: '其他' },
];

const newsItems = [
    {
        img: "/rule.webp",
        title: "次元通讯社区规则",
        url: "https://bwbbsvqza3t.feishu.cn/wiki/Bk9wwCpCAiRBrJkrBIFcOC5Vn9b",
    },
    {
        img: "/events/group_chat.webp",
        title: "群聊功能现已上线",
        url: "https://bwbbsvqza3t.feishu.cn/wiki/JQLpwgXuQib9KokM6dDcWHsJnIf",
    },
    {
        img: "https://rr-cn-resource-bucket.s3.cn-northwest-1.amazonaws.com.cn/system/coin_system.webp",
        title: "金币系统现已上线，点击查看规则",
        url: "https://bwbbsvqza3t.feishu.cn/wiki/MfHYwShpgirOXdkMcPjcp4HRnOg",
    },
]

function Carousel() {

    return (
        <div className="aspect-video w-full md:w-1/3 p-2">
            <div className="carousel rounded-xl">
                {
                    newsItems.map((item, index) => (
                        <div key={index} id={`carousel-item-${index}`} className={`carousel-item w-full relative`}>
                            <Link to={item.url}>
                                <img src={item.img} />
                                {item.title && <div className="absolute bottom-0 w-full text-white text-center p-4 bg-gradient-to-t from-black to-transparent">
                                    {item.title}
                                </div>}
                            </Link>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const CharacterListTabID = "home-page-active-tab"

function CharacterCollection() {
    return (
        <div className="p-1 w-1/2 md:w-1/3 lg:w-1/6">
            <Link to={`/search?q=${encodeURIComponent("海龟汤")}`} className="relative flex flex-col rounded-lg h-full overflow-hidden">
                <div className="absolute inset-0">
                    <ImageWithSkeleton className="w-full h-full object-cover" src={"/events/turtle.png"} alt={""} />
                    <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent bg-gradient-[rgba(0,0,0,0.7),rgba(0,0,0,0.35)_50%,rgba(0,0,0,0)_100%]"></div>

                </div>
                <div className="relative mt-auto p-2">
                    <div className="text-xs line-clamp-1 py-1 text-white">
                        <b>#海龟汤 合集</b>
                    </div>
                    <div className="text-xs line-clamp-3 text-slate-400 h-12">
                        要试试海龟汤吗？
                    </div>
                    <div className="text-slate-300 text-xs">
                        {"合集"}
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default function CharacterListPage() {

    const [activeTab, setActiveTab] = useState(() => {
        const sessionTab = sessionStorage.getItem(CharacterListTabID);
        return sessionTab !== null ? sessionTab : tabs[0].name;
    });
    useEffect(() => {
        sessionStorage.setItem(CharacterListTabID, activeTab);
    }, [activeTab]);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const tablist = () => (
        <div role="tablist" className="tabs tabs-bordered w-full justify-center items-center">
            {tabs.map((tab) => (
                <a
                    key={tab.name}
                    role="tab"
                    className={`tab ${activeTab === tab.name ? 'tab-active' : ''}`}
                    onClick={() => handleTabClick(tab.name)}
                >
                    {tab.name}
                </a>
            ))}
        </div>
    )

    const navbar = () => {
        return (
            <div className="navbar sticky top-0 w-full z-[99] bg-base-100">
                <Link to="/search" className="w-full mx-4">
                    <label className="input input-bordered flex items-center gap-2 w-full">
                        <p className="grow text-slate-400 pe-8">搜索角色... </p>
                        <i className="fa-solid fa-search" />
                    </label>
                </Link>
            </div>
        )
    }

    return (
        <div className="h-full w-full pt-[60px] pb-32">
            <div className="flex flex-col items-center h-full">
                <div className="flex flex-col w-full">
                    {tablist()}
                    <div className="flex flex-col space-y-5 p-4 pt-0">
                        <div className="w-full flex flex-wrap">
                            {activeTab == tabs[0].name && <CharacterBrowse sorting="combined" prefix="character-browse-combined" authed={false} user_id={undefined} >
                                <Carousel />
                            </CharacterBrowse>}
                            {activeTab == tabs[1].name && <CharacterBrowse sorting="latest" prefix="character-browse-latest" authed={false} user_id={undefined} />}
                            {activeTab == tabs[2].name && <CharacterBrowse sorting="combined" prefix="character-browse-male" gender="男" authed={false} user_id={undefined} />}
                            {activeTab == tabs[3].name && <CharacterBrowse sorting="combined" prefix="character-browse-female" gender="女" authed={false} user_id={undefined} />}
                            {activeTab == tabs[4].name && <CharacterBrowse sorting="combined" prefix="character-browse-other" gender="其他" authed={false} user_id={undefined} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
