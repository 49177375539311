import React, { useEffect, useRef, useState } from "react"
import { generateImage } from "../utils/api.ts"
import Zoom from 'react-medium-image-zoom'

export default function ImageGenerator({placeholder, prompt, src, reference, onComplete}) {

    const [imagePrompt, setImagePrompt] = useState<string>(prompt)
    const [imageSrc, setImageSrc] = useState<string>(src)
    const [imageLoading, setImageLoading] = useState(false)
    const [useRefImage, setUseRefImage] = useState(true)

    const imageGenerateDialogRef = useRef<null | HTMLDialogElement>(null)

    const improvePrompt = (prompt) => `${prompt},best quality,masterpiece`

    useEffect(()=>{
        setImageSrc(src)
        setImagePrompt(prompt)
    },[prompt, src])

    const generateImageWrapper = async() => {
        if (imageLoading || !imagePrompt) {
          return
        }

        const improvedPrompt = improvePrompt(imagePrompt)
        setImageSrc("")
        setImageLoading( true )
        const images = await generateImage(improvedPrompt, useRefImage ? reference: null);
        if (images.length > 0) {
            setImageSrc(images[0])
        }
        setImageLoading(false)
    }

    const handleImageUpload = (e) => {
        if (e.target.files && e.target.files[0]) {
            const img = e.target.files[0];
            const url = URL.createObjectURL(img)
            setImageSrc(url);
        } else {
            URL.revokeObjectURL(imageSrc)
            setImageSrc("")
        }
    }

    const onCompleteWrapper = (e) => {
        onComplete(imagePrompt, imageSrc)
    }

    const onDelete = (e) => {
        onComplete("", "")
    }

    const refChecker = () => (
        <div className="flex flex-row gap-2 py-2">
            <input type="checkbox" checked={useRefImage} onClick={()=>setUseRefImage(!useRefImage)} className="checkbox checkbox-secondary" />
            <p>参考角色头像</p>
        </div>
    )

    const imageGeneratorDialog = () => {
        return (
            <dialog ref={imageGenerateDialogRef} className="modal">
                <div className="modal-box space-y-2">
                    <h3 className="font-bold text-lg">添加图片</h3>
                    <div className="flex flex-col space-y-2" >
                        <label htmlFor="portrait-upload" className="btn btn-primary mt-4">本地上传</label>
                        <input id="portrait-upload" className="file-input mt-4" type="file" onChange={handleImageUpload} hidden/>
                        <p className="text-sm text-slate-400">ℹ️ 请勿上传侵权图片</p>
                    </div>
                    {imageSrc ? <Zoom><img src={imageSrc} alt="portrait" className="h-48 rounded" /></Zoom>: null}
                    <div className="modal-action">
                        <form method="dialog" className="space-x-4">
                            <button className="btn btn-error" onClick={onDelete}>删除</button>
                            <button className="btn" onClick={onCompleteWrapper}>保存</button>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        )
    }
    
    function showImageGenerateDialog() {
        if (imageGenerateDialogRef && imageGenerateDialogRef.current) imageGenerateDialogRef.current.showModal()
    }

    return (
      <> 
        {imageGeneratorDialog()}
        <button className="flex justify-center items-center h-36 w-36 border-dashed border-2 border-slate-400 rounded" onClick={showImageGenerateDialog}>
            {imageSrc ? <img src={imageSrc} alt="generated image" className="h-36 rounded" />: placeholder}
        </button>
      </>
    )
}
