import React, { useEffect, useState } from 'react';
import NavbarContainer from '../NavbarContainer.tsx';

interface ImageResponse {
  images: string[];
}

const ImageSelection = ({character_id, selected, setSelected}) => {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/characters/${character_id}/images`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: ImageResponse = await response.json();
        setImages(data.images);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, [character_id]);

  return (
        <div className="grid grid-cols-3 md:grid-cols-6 gap-2 p-1">
            {images.map((image, index) => (
            <div key={index} className="relative">
                <img
                    src={image}
                    alt={`background ${index + 1}`}
                    className={"aspect-[3/4] rounded-lg object-cover"}
                    onClick={() => setSelected(image)}
                />
                {selected === image && (
                    <div className="absolute bottom-0 w-full justify-center flex flex-row p-1 bg-black bg-opacity-70 rounded-b-lg">
                        <i className="fa-regular fa-circle-check text-lg text-green-500"></i>
                    </div>
                )}
            </div>
            ))}
      </div>
  );
};

export default ImageSelection;