export function parseImageUrlsFromMarkdown(mdString: string): string[] {
    const regex = /!\[.*?\]\((.*?)\)/g;
    const urls: string[] = [];
    let match: RegExpExecArray | null;

    while ((match = regex.exec(mdString)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (match.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The first capturing group (.*?) matches the URL
      if (match[1]) {
        urls.push(match[1]);
      }
    }

    return urls;
}

export function removeImageUrlsFromMarkdown(mdString: string): string {
  const regex = /!\[.*?\]\(.*?\)/g;
  return mdString.replace(regex, '');
}