import React from "react"
import TextareaAutosize from 'react-textarea-autosize';


export default function QuestionEntry({ question, choices, setChoices }) {

    const handleInpntChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //event.target.name
        setChoices({
            ...choices,
            [event.target.name]: event.target.value
        });
    };

    const handleOptionSelect = (question, option: string) => {
        if (question.multiple) {
            // For multiple selection, toggle the option in an array
            setChoices({
                ...choices,
                [question.id]: choices[question.id] ?
                    choices[question.id].includes(option)
                        ? choices[question.id].filter(item => item !== option)
                        : [...choices[question.id], option]
                    : [option],
            });
        } else {
            setChoices({ ...choices, [question.id]: option });
        }
    };

    const content = () => {
        if (question.options) {
            return (
                <div className="flex flex-row flex-wrap">
                    {question.options.map((option) => (
                        <div className="p-1">
                            <button
                                key={option}
                                className={`btn btn-outline btn-md ${choices[question.id]?.includes(option) ? 'btn-secondary' : 'btn-success'}`}
                                onClick={() => handleOptionSelect(question, option)}
                            >
                                {option}
                            </button>
                        </div>
                    ))}
                </div>
            )
        }
        if (question.textArea) {
            return (
                <>
                    <TextareaAutosize name={question.id} placeholder={question.placeHolder} minRows={3} maxLength={question.maxLen} value={choices[question.id]} onChange={handleInpntChange} className="textarea textarea-bordered w-full max-w-md"></TextareaAutosize>
                    {question.maxLen && <div className="text-sm text-slate-400">({choices[question.id] ? choices[question.id].length : 0}/{question.maxLen})</div>}
                </>
            )
        }
        return (
            <input type="text" name={question.id} placeholder={question.placeHolder} maxLength={question.maxLen} value={choices[question.id]} onChange={handleInpntChange} className="input input-bordered w-full max-w-md" />
        )
    }

    return (
        <div className="flex flex-col">
            <div className="label">{question.label}</div>
            {content()}
        </div>
    )
}