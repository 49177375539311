import React from "react";
import CommentSection from "../common/CommentSection.tsx";
import NavbarContainer from "../NavbarContainer.tsx";
import { useParams } from "react-router-dom";

export default function CharacterComments() {

    const {charID} = useParams()

    return (
        <NavbarContainer title="">
            <CommentSection header={null} commentsEndpoint={`characters/${charID}`}/>
        </NavbarContainer>
    )
}