import React, { useEffect } from "react";
import { useState } from "react";
import { writerUpdateCharacterAPI, writerFetchCharacterAPI, writerCreateTopicAPI, uploadResource, generateImage, writerCreateScene, writerCreateIM, writerCreateMonologue, ttsLong } from "../utils/api.ts";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingPage from "../LoadingPage.tsx";
import Markdown from "../common/markdown.tsx";
import NavbarContainer from "../NavbarContainer.tsx";

const CharacterProfile = ({ character, setCharacter }) => {
  const [editMode, setEditMode] = useState(false);

  const handleEdit = async () => {
    if (editMode) {
      await writerUpdateCharacterAPI(character.character_id, character.bio, character.extra_prompt, character.public, () => { })
    }
    // Implement edit logic
    setEditMode(!editMode);
  };

  return (
    <div className="flex flex-col card card-body">
      <p>用户昵称：{character.user_nickname}</p>
      <p>微信客户端：{character.wechat_puppet?"On": "Off"}</p>
      <p>人物设定：</p>
      <p>{character.description}</p>
      <div className="flex flex-col">
      <textarea
          className="textarea grow"
          disabled={!editMode}
          value={character.bio}
          onChange={(e) => setCharacter({ ...character, bio: e.target.value })}
        />
        <textarea
          className="textarea grow"
          disabled={!editMode}
          value={character.extra_prompt}
          onChange={(e) => setCharacter({ ...character, extra_prompt: e.target.value })}
        />
        <label className="label cursor-pointer">
          <span className="label-text">公开</span>
          <input type="checkbox" disabled={!editMode} checked={character.public} onClick={()=>setCharacter({ ...character, public: !character.public })} className="checkbox checkbox-primary" />
        </label>
        <button onClick={handleEdit} className="btn btn-primary">{editMode ? "Confirm" : "Edit"}</button>
      </div>
    </div>
  );
};

const ImageGenerator = ({ character }) => {
  const [prompt, setPrompt] = useState('');
  const [generatedImages, setGeneratedImages] = useState<string[]>([]);
  const [loading, setLoading] = useState(false)
  const [enableRef, setEnableRef] = useState(true);

  const handleSubmit = async () => {
    setLoading(true)
    var image_url = null
    if (enableRef) {
      image_url = character.avatar_url
    }
    const images = await generateImage(prompt, image_url)
    setGeneratedImages(images)
    setLoading(false)
  };

  const handleCheckboxChange = (event) => {
    setEnableRef(event.target.checked);
  };

  return (
    <div className="flex flex-col card card-body">
      <p>图片生成：</p>
      <div className="flex flex-row">
        <input
          className="input input-bordered w-full max-w-xs"
          placeholder="additional image prompt"
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleSubmit}>Generate</button>
      </div>
      <p>基础立绘: <b>{character.appearance_prompt}</b></p>
      <div className="flex flex-row">
        <div>参考基础立绘:</div>
        <input
          type="checkbox"
          className="toggle"
          checked={enableRef}
          onChange={handleCheckboxChange}
        />
      </div>
      <img className={"w-32 h-32"} src={character.avatar_url} alt={`Original image`} />
      <div>
        {loading? <span className="loading loading-spinner loading-lg"></span>: null}
        {generatedImages.map((image, index) => (
          <img key={index} src={image} alt={`Generated ${index}`} />
        ))}
      </div>
    </div>
  );
};

const AsmrLinkCreator = () => {
  const [audio, setAudio] = useState("");
  const [bg, setBg] = useState("");
  const [link, setLink] = useState('');

  const handleAudioChange = (e) => {
    setAudio(e.target.value);
  };

  const handleBgChange = (e) => {
    setBg(e.target.value);
  };

  const handleSubmit = async (e) => {
    if (!audio || !bg) return
    setLink('')
    setLink(`/assets/asmr?audio=${encodeURIComponent(audio)}&bg=${encodeURIComponent(bg)}`)
  }

  return (
    <div className="card card-body">
      <p>ASMR</p>
      <div className="form-control">
        <label className="label">
          <span className="label-text">音频(mp3)</span>
        </label>
        <input onChange={handleAudioChange} accept=".mp3" className="input input-bordered" />
      </div>

      <div className="form-control mt-4">
        <label className="label">
          <span className="label-text">图片(jpg,png)</span>
        </label>
        <input onChange={handleBgChange} accept=".png,.jpg" className="input input-bordered" />
      </div>

      <button className="btn btn-primary mt-4" onClick={handleSubmit} disabled={!(audio&&bg)}>
        Cook!
      </button>
      { link?
        <Link target="_blank" to={link} className="btn btn-primary mt-4">
          Go to link
        </Link>: null
      }
    </div>
  );
};

const RecentHistory = ({ character, reload }) => {

  if (!character.recent_history.length) {
    return null
  }

  const lastTopicDateDiff = () => {
    var dateToCompare = character.recent_history[0].date
    for (let i = character.recent_history.length-1; i>=0; i--) {
      let msg = character.recent_history[i]
      if (msg.type === "topic") {
        dateToCompare = msg.date
        break
      }
    }
    const diff = ((new Date()).getTime() - dateToCompare) / 1000 / 60 / 60 / 24
    return <p className={diff > 2 ? "bg-red-300" : "bg-green-300"}>最近一次话题是{diff}天前</p>
  }
  return (
    <div className="flex flex-col card card-body">
      <p>聊天记录</p>
      <div className="h-96 overflow-y-scroll border">
        {character.recent_history.map((item, index) => (
          <div
            key={index}
            className={`p-1 border-b border-gray-200 ${item.type === "topic" ? "bg-yellow-300" : item.role === "user" ? "bg-primary": ""}`}
          >
            <Markdown>{item.content}</Markdown>
            <p>Role: {item.role}  Date: {new Date(item.date).toLocaleString()} Rating: {item.rating}</p>
          </div>
        ))}
      </div>
      <button className="btn" onClick={reload}>Reload</button>
      {lastTopicDateDiff()}
    </div>
  );
}

const TopicEditor = ({ character }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [followUp, setFollowUp] = useState("");
  const [triggerAt, setTriggerAt] = useState("");
  const [triggerType, setTriggerType] = useState("always");

  const confirmModalID = "confirm-modal"

  const disabled = !title || !content || (!!followUp && !triggerAt)

  const handleTriggerTypeChange = (event) => {
    setTriggerType(event.target.value);
  };

  const handleSubmit = async () => {
    if (disabled) {
      return
    }
    await writerCreateTopicAPI(character.character_id, title, content, followUp, triggerAt, triggerType, () => { })
  };

  const showConfirmSubmit = () => {
    const modal = document.getElementById(confirmModalID) as HTMLDialogElement
    if (modal) modal.showModal()
  }

  const placeHolder = `这是文本
  ![图片名称](www.example.com/image.jpg)
  [链接名称](www.example.com)`

  return (
    <div className="flex flex-col card card-body">
      <p>编辑话题</p>
      <div className="flex flex-col">
        <input
          className="input input-bordered w-full max-w-xs"
          placeholder="话题标题"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <textarea
          className="textarea textarea-bordered h-32"
          placeholder={placeHolder}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <Markdown>{content}</Markdown>
        <textarea
          className="textarea textarea-bordered h-16"
          placeholder="followup内容（可选）"
          value={followUp}
          onChange={(e) => setFollowUp(e.target.value)}
        />
        <Markdown>{followUp}</Markdown>
        <input type="datetime-local" value={triggerAt} onChange={e => setTriggerAt(e.target.value)} />
        <select
          className="select select-bordered w-full max-w-xs"
          value={triggerType}
          onChange={handleTriggerTypeChange}
        >
          <option value="always">总是触发</option>
          <option value="nores">无回复触发</option>
        </select>
        <button onClick={showConfirmSubmit} className="btn btn-primary" disabled={disabled}>Submit</button>
      </div>
      <dialog id={confirmModalID} className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">确认给{character.user_nickname}发送的内容</h3>
          <p className="py-4">{content}</p>
          <div className="modal-action">
            <form method="dialog" className="space-x-4">
              <button className="btn btn-error" onClick={handleSubmit}>确定</button>
              <button className="btn" >取消</button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  );
};

const SceneWriter = ({ character }) => {
  const [scene, setScene] = useState("");
  const [idea, setIdea] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (loading) {
      return
    }
    setLoading(true)
    const res = await writerCreateScene(character.character_id, idea, () => {})
    if (res ) {
      setScene(res.scene)
      setIdea(res.idea)
    }
    setLoading(false)
  };

  return (
    <div className="flex flex-col card card-body">
      <p>生成角色扮演场景</p>
      <input
        className="input input-bordered w-full"
        placeholder="meta idea ({user}和{char}去日本旅游) (不填则随机生成)"
        type="text"
        value={idea}
        onChange={(e) => setIdea(e.target.value)}
      />
      <button onClick={handleSubmit} className="btn btn-primary" disabled={loading}>Generate</button>
      {loading? <span className="loading loading-spinner loading-lg"></span>: null}
      <p>{scene}</p>
    </div>
  );
};

const IMWriter = ({ character }) => {
  const [message, setMessage] = useState("");
  const [topic, setTopic] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (loading) {
      return
    }
    setLoading(true)
    const res = await writerCreateIM(character.character_id, topic, () => {})
    if (res ) {
      setMessage(res)
    }
    setLoading(false)
  };

  return (
    <div className="flex flex-col card card-body">
      <p>微信风格消息</p>
      <input
        className="input input-bordered w-full"
        placeholder="话题"
        type="text"
        value={topic}
        onChange={(e) => setTopic(e.target.value)}
      />
      <button onClick={handleSubmit} className="btn btn-primary" disabled={loading || !topic}>Generate</button>
      {loading? <span className="loading loading-spinner loading-lg"></span>: null}
      <p>{message}</p>
    </div>
  );
};

const MonologueWriter = ({ character }) => {
  const [message, setMessage] = useState("");
  const [topic, setTopic] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState<string>("");
  const [audioURL, setAudioURL] = useState<string>("");

  const handleSubmitTopic = async () => {
    if (loading) {
      return
    }
    setLoading(true)
    const res = await writerCreateMonologue(character.character_id, topic, () => {})
    if (res ) {
      setMessage(res)
    }
    setLoading(false)
  };

  const handleSubmitText = async () => {
    if (loading) {
      return
    }
    setLoading(true)
    const res = await ttsLong(character.character_id, text)
    if (res ) {
      setAudioURL(res)
    }
    setLoading(false)
  };

  return (
    <div className="flex flex-col card card-body">
      <p>独白</p>
      <input
        className="input input-bordered w-full"
        placeholder="话题"
        type="text"
        value={topic}
        onChange={(e) => setTopic(e.target.value)}
      />
      <button onClick={handleSubmitTopic} className="btn btn-primary" disabled={loading || !topic}>Generate</button>
      {loading? <span className="loading loading-spinner loading-lg"></span>: null}
      <p>{message}</p>
      <input
        className="input input-bordered w-full"
        placeholder="语音文本"
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <button onClick={handleSubmitText} className="btn btn-primary" disabled={loading || !text}>Generate</button>
      {loading? <span className="loading loading-spinner loading-lg"></span>: null}
      { audioURL && <audio controls>
        <source src={audioURL} type="audio/mpeg" />
      </audio>}
      <p>{audioURL}</p>
    </div>
  );
};

const ResourceUpload = () => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    if (!file) return
    setLoading(true)
    setLink('')
    const fileURL = await uploadResource(file, ()=>{})
    if (!fileURL) {
      return
    }
    setLink(fileURL)
    setLoading(false)
  }

  return (
    <div className="card card-body">
      <p>文件上传</p>
      <div className="form-control">
        <input type="file" onChange={handleFileChange} className="input input-bordered" />
      </div>

      <button className="btn btn-primary mt-4" onClick={handleSubmit} disabled={!(file) || loading}>
        {loading? <span className="loading loading-spinner loading-lg"></span>: "Upload!"}
      </button>
      { link?
        <Link target="_blank" to={link} className="btn btn-primary mt-4">
          Go to link
        </Link>: null
      }
    </div>
  );
};


function WriterPage() {

  const navigate = useNavigate()
  let { characterID } = useParams()
  
  const [character, setCharacter] = useState(null);

  const loadData = async () => {
    try {
      const char = await writerFetchCharacterAPI(characterID || "", () => {navigate("/login")})
      console.log(char)
      setCharacter(char)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    loadData()
  }, []);

  if (!character) {
    return <LoadingPage />
  }

  return (
    <NavbarContainer title="工作台">
      <h1 className="text-2xl font-bold text-center">Story Writers' Dashboard</h1>
      <CharacterProfile character={character} setCharacter={setCharacter} />
      <RecentHistory character={character} reload={loadData} />
      <TopicEditor character={character} />
      <ImageGenerator character={character} />
      <SceneWriter character={character}  />
      <IMWriter character={character}  />
      <MonologueWriter character={character}  />
      <ResourceUpload />
      <AsmrLinkCreator />
    </NavbarContainer>
  );
}

export default WriterPage;