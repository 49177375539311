import React from "react";
import Markdown from "./markdown.tsx";

export const addAsterisksToChineseParentheses = (text) => {
    const regex = /（(.*?)）/g;
    return text.replace(regex, ' *（$1）* ');
}

export default function ChatBubble({role, content}) {
    const isAI = role === "assistant"
    const isSystem = role === "system"
    if (isSystem) {
        return (
            <div className={`chat chat-start`}>
                <div className={`break-words max-w-full chat-bubble bg-black text-white`}>
                    <Markdown>
                        {content}
                    </Markdown>
                </div>
            </div>
        )
    } 
    return (
        <div className={`chat ${isAI ? "chat-start" : "chat-end"}`}>
            <div className={`break-words max-w-full chat-bubble ${isAI ? "chat-bubble-primary" : "chat-bubble-neutral"}`}>
                <Markdown>
                    {addAsterisksToChineseParentheses(content)}
                </Markdown>
            </div>
        </div>
    )
}