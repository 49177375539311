import React from 'react';
import { Broadcast } from '../utils/type.ts';
import BroadcastEntry from './BroadcastEntry.tsx';
import InfinitePullToRefreshScroll from '../common/InfiniteScroller.tsx';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';

interface BroadcastsProps {
    broadcasts: Broadcast[];
}

function BroadcastBrowse({ broadcasts }: BroadcastsProps) {
    return (
        <>
            {broadcasts.map((broadcast, index) => (
                <div key={index} className="p-2 md:p-4 w-full md:w-1/2">
                    <BroadcastEntry broadcast={broadcast} />
                </div>
            ))}
        </>
    );
};


export function BroadcastBrowseWrapper() {

    const fetchItems = async (page: number) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/broadcast/topics?page=${page}&page_size=20`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json();
            console.log(data["topics"])
            return data["topics"]
        } catch (error) {
            toast.error("获取话题失败🙅");
            return []
        }
    }

    return (
        <InfinitePullToRefreshScroll prefix="broadcast-browse" fetchItems={fetchItems}>
            {({ items }) => (
                <BroadcastBrowse broadcasts={items} />
            )}
        </InfinitePullToRefreshScroll>
    )
}

export default BroadcastBrowse;