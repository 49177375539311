import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import Cookies from "js-cookie"
import React from 'react';

import { Chart } from 'react-google-charts';

function LineChart({data}) {

  return (
    <Chart
      chartType="LineChart"
      data={[
        ["Date", "value"],
        ...data
      ]}
    />
  );
}

export default function DashboardPage() {
    const [data, setData] = useState([])

    const fetchDashboardData = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/dashboard`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json()
            console.log(data)
            setData(data)
        } catch (error) {
            toast.error("没有权限")
            return
        }
    }

    useEffect(() => {
        fetchDashboardData()
    }, [])

    return (
        <div className="p-4 w-full max-w-md">
            {
                data && data.map((entry, index)=>(
                    <div key={index}>
                    <p>{entry["title"]}</p>
                    {<LineChart data={entry["data"]}/>}
                    </div>
                ))
            }
        </div>
    )
}