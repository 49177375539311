import React, { useEffect, useState } from "react";
import NavbarContainer from "../NavbarContainer.tsx";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { useParams, useSearchParams } from "react-router-dom";
import { useUser } from "../context/UserContext.tsx";
import LoadingPage from "../LoadingPage.tsx";

export default function WithdrawPage() {

    const {user, fetchUserAsync} = useUser();

    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState(0)

    useEffect(()=>{
        fetchUserAsync()
    }, [])

    const withdrawToCoins = async () => {
        setLoading(true)
        const res = await fetch(`${process.env.REACT_APP_API_URL}/user/withdraw`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({amount: amount}),
        });
        if (res.status != 200) {
            toast.error("提现失败，请稍后再试")
            setLoading(false)
            return
        }
        await fetchUserAsync()
        toast.success("提现成功")
        setAmount(0)
        setLoading(false)
    }

    if (!user) {
        return <LoadingPage />
    }
    
    return (
        <NavbarContainer title={"收益提现"}>
            <div className="flex flex-row gap-2 items-center text-xl m-4 p-4 bg-base-100 max-w-md">
                <i className="fa-solid fa-coins"></i>
                {user.inspiration_coin}
            </div>
            <div className="flex flex-col justify-start p-4 gap-4">
            请选择提现方式
            <div className="flex flex-row items-center gap-2">
                <input type="checkbox" checked className="checkbox" />
                <div>转入余额</div>
                <i className="fa-solid fa-coins"></i>
                {amount}
            </div>
            <input className="range w-full max-w-md" type="range" max={user.inspiration_coin} min="0" name='inspiration_price' value={amount} onChange={e=>setAmount(Number(e.target.value))} ></input>
            <button onClick={withdrawToCoins} disabled={loading || amount === 0} className="btn btn-secondary w-20 mt-8">确定</button>
            </div>
        </NavbarContainer>
    )
}