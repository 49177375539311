import React, { useState } from "react";
import { toast } from "react-hot-toast"
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "./context/UserContext.tsx";


export default function LoginPage({handleRedirectToRegister}) {

    const {fetchUserAsync} = useUser()

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [processing, setProcessing] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()

    const goBackOrHome = () => {
        if (location.key === 'default') {
            navigate("/", { replace: true })
        } else {
            navigate(-1)
        }
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleLoginRequest = async () => {

        console.log("Handle login request")
        console.log(`Username: ${username}`)
        console.log(`Password: ********************`)
        setProcessing(true)
        var res;
        try {
            res = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "username": username,
                    "password": password
                }),
            })
        } catch (e) {
            toast.error("登录失败了🙅")
            setProcessing(false)
            return
        }

        const status = await res.status;
        if (status !== 200) {
            toast.error("登录失败了🙅")
            setProcessing(false)
            return
        }

        const data = await res.json();
        const access_token = data.access_token;
        Cookies.set('ringriseusertoken', access_token, { expires: 7 })
        await fetchUserAsync()
        setProcessing(false)
        goBackOrHome()
    };

    return (
        <div className="flex flex-col h-screen justify-center items-center">
            <div className="w-full p-6 m-auto bg-base-100 rounded-md lg:max-w-lg">
                <div className="flex flex-row" >
                    <img src="/logo192.png" alt="logo" className="w-20 h-20"/>
                    <h1 className="text-3xl font-semibold text-center p-8">欢迎回来</h1>
                </div>
                <div className="space-y-4">
                    <div>
                        <label className="label">
                            <span className="text-base label-text">用户名</span>
                        </label>
                        <input type="text" placeholder="邮箱或者用户名" className="w-full input input-bordered input-primary" onChange={handleUsernameChange} />
                    </div>
                    <div>
                        <label className="label">
                            <span className="text-base label-text">密码</span>
                        </label>
                        <input type="password" placeholder="密码"
                            className="w-full input input-bordered input-primary" onChange={handlePasswordChange} />
                    </div>
                    <div className="flex flex-col">
                        <button className="btn btn-primary" onClick={handleLoginRequest} disabled={processing}>登录</button>
                    </div>
                    <hr className="solid p-y-10"></hr>
                    <div className="flex flex-col">
                        <button className="btn btn-accent" onClick={handleRedirectToRegister} disabled={processing}>注册新账号</button>
                    </div>
                </div>
            </div>
        </div>
    )
}