import React, { useEffect, useState } from "react";
import NavbarContainer from "../NavbarContainer.tsx";
import { useParams } from "react-router-dom";
import ChatBubble from "../common/ChatBubble.tsx";

export default function ChatSharePage() {

    const {charID} = useParams()
    const historyLSID = `history-${charID}`
    const [history, setHistory] = useState<Array<Object> | null>(() => {
      const localHistory = localStorage.getItem(historyLSID);
      return localHistory !== null ? JSON.parse(localHistory) : null;
    });
    useEffect(() => {
      if (history?.constructor === Array) {
        localStorage.setItem(historyLSID, JSON.stringify(history));
      }
    }, [history]);

    return (
        <NavbarContainer title="分享聊天">
            <div className={` w-screen md:w-1/2 self-center overflow-y-auto overflow-x-hidden`}>
                <div className="pt-[64px] pb-20 px-4 space-y-4">
                    {history && history.map((entry) => (
                        !entry["hide"] && <ChatBubble role={entry["role"]} content={entry["content"]}/>
                    ))}
                </div>
            </div>
        </NavbarContainer>
    )
}