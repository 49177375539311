import React, { useEffect, useState } from "react";
import NavbarContainer from "../NavbarContainer.tsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { prettifyContent, sample_image_url } from "../utils/content.ts";
import LoadingPage from "../LoadingPage.tsx";
import Markdown from "../common/markdown.tsx";
import { useUser } from "../context/UserContext.tsx";
import { parseImageUrlsFromMarkdown } from "../utils/markdown.ts";
import BiliBiliPlayer from "../common/BilibiliPlayer.tsx";
import { cookCharacterBroadcast, fetchCharacterAPI } from "../utils/api.ts";
import { Broadcast, Character } from "../utils/type.ts";
import { useCharacter } from "../context/CharacterContext.tsx";
import BroadcastEntry from "./BroadcastEntry.tsx";
import ChatBubble from "../common/ChatBubble.tsx";

function sortTopicsByAuthor(topics: Broadcast[], author_username: string): Broadcast[] {
    // Clone the array to avoid mutating the original array
    return [...topics].sort((a, b) => {
      // Check if either topic has the preferred author_id
      const aIsPreferred = a.author_username === author_username ? 1 : 0;
      const bIsPreferred = b.author_username === author_username ? 1 : 0;
  
      // Sort such that preferred author_ids come first
      return bIsPreferred - aIsPreferred;
    });
  }

function TopicsRecommend({current_author}) {
    const { publicTopics, fetchPublicTopicsAsync } = useCharacter()

    useEffect(()=>{
        fetchPublicTopicsAsync()
    }, [])

    return (
        <div>
            {
                sortTopicsByAuthor(publicTopics, current_author).map((topic, index) => (
                    <BroadcastEntry key={index} broadcast={topic} />
                ))
            }
        </div>
    )
}

export default function BroadcastTopicPage() {

    const { topic_id } = useParams();
    const [ processing, setProcessing ] = useState(false)
    const [character, setCharacter] = useState<null | Character>(null)

    const { user } = useUser()

    const navigate = useNavigate()

    const [topic, setTopic] = useState<Broadcast | null>(null)

    const fetchTopic = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/broadcast/topics/${topic_id}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json();
            const content = data["content"]
            var sample_image = ""
            const urls = parseImageUrlsFromMarkdown(content)
            if (urls && urls.length) {
                sample_image = urls[0]
            }

            setTopic({
                ...data,
                content: content,
                sample_image: sample_image,
            })

            // fetch character
            if (data["character_id"]) {
                const character = await fetchCharacterAPI(data["character_id"])
                setCharacter(character)
            }
        } catch (error) {
            toast.error("话题未公开")
            return
        }
    }

    useEffect(()=>{
        setTopic(null)
        fetchTopic()
    }, [topic_id])

    if (topic === null) {
        return <LoadingPage />
    }
    
    const editable = !!(topic_id && user?.username === topic.author_username)

    const navEnd = ()=>(
        <>
            <button className="btn btn-ghost" disabled={!editable} onClick={()=>navigate(`/broadcasts/create?topic_id=${topic_id}`)}>
                <i className="far fa-edit"></i>
            </button>
        </>
    )

    const assetsViewer = () => {
        return (
            <> 
                {topic.sample_image.startsWith("BV") ?
                    <BiliBiliPlayer bv={topic.sample_image} />
                : <img src={topic.sample_image} alt={topic.title} className="h-full" />
                }
            </>
        )
    }

    const onChatWithTopicClicked = async () => {
        setProcessing(true)
        const result = await cookCharacterBroadcast(topic.character_id, Number(topic_id))
        if (result) {
            navigate(`/c/${topic.character_id}`)
        } else {
            toast.error("生成失败，请稍后再试");
        }
        setProcessing(false)
    }

    const characterSection = () => (
        <Link to={`/character/${character?.id}`} >
            <div className="flex flex-row items-center gap-2 p-2 rounded-lg bg-slate-200 w-full max-w-md" >
            <img className="w-12 h-12 rounded-full" src={character?.avatar_url} />
            <p className="whitespace-nowrap">{character?.name}</p>
            <p className="line-clamp-1 text-slate-400">{character?.bio}</p>
            </div>
        </Link>
      )

    return (
        <NavbarContainer title="" navEnd={navEnd} key={topic_id}>
            <div className="flex flex-col justify-center items-center">
                <div className="w-full md:w-1/2 flex flex-col space-y-4 bg-base-100 pb-4">
                    {topic.sample_image && assetsViewer()}
                    <div className="flex flex-col gap-2 px-4">
                        <p className="text-xl font-medium">{topic.title}</p>
                        <p className="text-xs">@ {topic.author_nickname}</p>
                        <p className="text-xs text-slate-500"><i className="fa-regular fa-calendar"></i> {topic.time_created.split("T")[0]}</p>
                    </div>
                    <div className="px-4">
                        {characterSection()}
                    </div>
                    <div className="bg-base-100 rounded px-4 prose">
                        <Markdown>{topic.hide_content ? "[设定已隐藏]": prettifyContent(topic)}</Markdown>
                    </div>
                    { topic.greeting && <ChatBubble role={"assistant"} content={topic.greeting} />}
                    <div className="px-4 flex flex-row gap-2">
                        <button className="btn btn-sm btn-outline px-4 flex-1" disabled={processing} onClick={onChatWithTopicClicked} >聊这个话题</button>
                        <Link className="btn btn-sm btn-outline px-4 flex-1" to={`/c/${topic.character_id}`} >聊天</Link>
                    </div>
                </div>
            </div>
        </NavbarContainer>
    );
}