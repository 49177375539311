import React, { useEffect, useRef, useState } from "react";
import NavbarContainer from "../NavbarContainer.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../context/UserContext.tsx";
import { useCharacter } from "../context/CharacterContext.tsx";
import { Character } from "../utils/type.ts";
import toast from "react-hot-toast";
import Cookies from "js-cookie"
import ReactTextareaAutosize from "react-textarea-autosize";
import Markdown from "../common/markdown.tsx";

const UpdateMemberDialog = ({ title, icon, characters, onConfirm }) => {
    const [selectedMembers, setSelectedMembers] = useState<any>([]);
    const ref = useRef<HTMLDialogElement>(null)

    const handleCheckboxChange = (member) => {
        setSelectedMembers((prevSelected) => {
            if (prevSelected.some((m) => m.id === member.id)) {
                return prevSelected.filter((m) => m.id !== member.id);
            } else {
                return [...prevSelected, member];
            }
        });
    };

    const handleAdd = () => {
        onConfirm(selectedMembers);
        setSelectedMembers([]);
        ref.current?.close()
    };

    return (
        <>
            <button className="btn btn-circle" onClick={() => ref.current?.showModal()}>
                <i className={icon}></i>
            </button>
            <dialog className="modal" ref={ref}>
                <div className="modal-box max-h-[66vh]">
                    <h3 className="font-bold text-lg">{title}</h3>
                    <div className="my-4 flex flex-col gap-1">
                        {characters.map((member) => (
                                <div  key={member.id} className="flex flex-row items-center gap-2">
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        value={member.id}
                                        checked={selectedMembers.some((m) => m.id === member.id)}
                                        onChange={() => handleCheckboxChange(member)}
                                    />
                                    <img src={member.avatar_url} alt="avatar" className="w-12 h-12 rounded-full" />
                                    {member.name}
                                </div>
                        ))}
                    </div>
                    <button className="btn btn-primary" onClick={handleAdd}>确认</button>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </>
        
    );
};

export default function ContentWizard() {

    const {groupID} = useParams()

    const navigate = useNavigate()

    const [characters, setCharacters] = useState<Character[]>([])
    const [ideas, setIdeas] = useState([])
    const [idea, setIdea] = useState("")
    const [content, setContent] = useState([])

    const {user} = useUser()
    const {linkedCharacters, fetchLinkedCharacterAsync} = useCharacter()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (user) {
            fetchLinkedCharacterAsync();
        }
    }, [user]);


    const handleAddMembers = async (newMembers) => {
        const updatedMembers = [
            ...characters,
            ...newMembers
        ].reduce((unique, member) => {
            if (!unique.some((m) => m.id === member.id)) {
                unique.push(member);
            }
            return unique;
        }, []);
        setCharacters(updatedMembers);
    };

    const handleRemoveMembers = async (membersToRemove) => {
        const updatedMembers = characters.filter(
            (member) => !membersToRemove.some((m) => m.id === member.id)
        );
        setCharacters( updatedMembers);
    };

    const ideaPrompt = () => {
        let dialogueSnippet = '我需要创作如下角色之间的对话片段 请列出三个灵感，每个用简短的一句话描述：\n';

        characters.forEach((character, index) => {
            dialogueSnippet += `角色${index + 1}: ${character.name}\n${character.extra_prompt}\n`;
        });

        dialogueSnippet += 'Output following this example JSON format: ["idea1", "idea2", ...]. Only returns JSON, DONNOT add anything else.'
        return dialogueSnippet
    }

    const dialogPrompt = () => {
        let dialogueSnippet = '我需要创作如下角色之间的对话片段：\n';

        characters.forEach((character, index) => {
            dialogueSnippet += `角色${index + 1}: ${character.name}\n${character.extra_prompt}\n`;
        });

        dialogueSnippet += `主题：${idea}\n`

        dialogueSnippet += 'Your writing style should be of internet novel, and use italic for third person writing.\nOutput following this example JSON format: [{"name": "CHARACTER_NAME", "dialog":"CHARACTER_DIALOG"}, ...]. Only returns JSON, DONNOT add anything else.'
        return dialogueSnippet
    }
      

    const fetchReply = async (content: string, model: number) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/llm/chat`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
              },
              body: JSON.stringify({
                model: model,
                messages: [{
                    "role": "user",
                    "content": content
                }]
              })
            });
        
            if (res.status !== 200) {
              toast.error("获取失败🙅");
              return ""
            }
        
            const data = await res.json();
            return data["reply"]
          } catch (e) {
            toast.error("获取失败🙅");
            return ""
          }
    }

    const generateIdeas = async () => {
        if (loading) return
        setLoading(true)
        const reply = await fetchReply(ideaPrompt(), 2)
        setLoading(false)
        setIdeas(JSON.parse(reply))
    }

    const generateDialog = async () => {
        if (loading) return
        setLoading(true)
        const reply = await fetchReply(dialogPrompt(), 5)
        setLoading(false)
        try {
            setContent(JSON.parse(reply))
        } catch {
            toast.error("invalid reply format")
            console.log(reply)
        }
    }

    return (
        <NavbarContainer title="Content Wizard">
            <div className="flex flex-col p-3 gap-4">
                
                <div className="flex flex-wrap gap-2">
                    {
                        characters.map((member, index)=>(
                            <img className="w-12 h-12 rounded-full" key={index} src={member.avatar_url} />
                        )) 
                    }
                    <UpdateMemberDialog
                        title={"添加成员"}
                        icon="fa-solid fa-plus"
                        characters={linkedCharacters}
                        onConfirm={handleAddMembers}
                    />
                    <UpdateMemberDialog
                        title="移除成员"
                        icon="fa-solid fa-minus"
                        characters={characters}
                        onConfirm={handleRemoveMembers}
                    />
                </div>
                <button
                    onClick={generateIdeas}
                    className="btn btn-primary mt-4 max-w-md w-full"
                    disabled={loading}
                >
                    Generate ideas
                </button>
                <div>
                    {
                        ideas && ideas.map((idea)=>(
                            <button onClick={()=>setIdea(idea)} className="btn">{idea}</button>
                        ))
                    }
                </div>
                <ReactTextareaAutosize placeholder={"Idea"} value={idea} onChange={e=>setIdea(e.target.value)} className="textarea textarea-bordered w-full max-w-md"/>
                <button
                    onClick={generateDialog}
                    className="btn btn-primary mt-4 max-w-md w-full"
                    disabled={loading}
                >
                    Generate dialog
                </button>
                <div>
                    {
                        content && content.map((dialog)=>(
                            <>
                                <p>{dialog["name"]}: </p>
                                <Markdown>{dialog["dialog"]}</Markdown>
                            </>
                        ))
                    }
                </div>
                <button className="btn rounded-full" disabled={loading || characters.length===0} onClick={() => navigate(`/content/create`, {
                    state: {
                        "value": JSON.stringify(content),
                        "character_id": characters[0].id,
                        "character_options": characters,
                    }
                })}>
                    Confirm
                </button>
            </div>
        </NavbarContainer>
    )
}