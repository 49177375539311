import React, { useRef } from 'react';
import { useState, useEffect } from "react";
import { prettifyContent, sample_image_url } from '../utils/content.ts';
import { Link } from 'react-router-dom';
import { Broadcast } from '../utils/type.ts';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';

export default function NewTopicButton({ character, cookAIText, processing }) {

  const [topics, setTopics] = useState<Broadcast[]>([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false)

  const ref = useRef<HTMLDialogElement>(null)

  async function fetchTopics() {
    if (!character) {
      return
    }
    setLoading(true);

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/broadcast/topics?page=1&page_size=20&character_id=${encodeURIComponent(character.id)}`, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
        },
      });
      const data = await res.json();
      setTopics(data["topics"])
      console.log(data["topics"])
      setLoading(false);
    } catch (error) {
      toast.error("获取话题失败🙅");
      setLoading(false);
      return
    }
  }

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    visible ? ref.current.showModal() : ref.current.close();
    if (visible) fetchTopics()
  }, [visible]);

  async function handleTopicSelected(topic: { id: number }) {
    if (!character) {
      return
    }
    closeModal()
    await cookAIText(character.id, topic.id, false)
  }

  function showModal() {
    setVisible(true)
  }

  function closeModal() {
    setVisible(false)
  }

  return (
    <>
      <button className="btn rounded-full" onClick={showModal} disabled={processing}>
        <i className="fa-regular fa-lightbulb"></i>
      </button>
      <dialog className="modal" ref={ref} style={{marginLeft: 0}}>
        <div className="modal-box flex flex-col space-y-2">
          <h3 className="font-bold text-lg">想让TA聊点什么...</h3>
          <div className="flex flex-col space-y-4">
            {!loading && topics.length == 0 && <p>这个角色还没有话题，为TA创建一个吧！</p>}
            {loading ? (
              <div className="text-center">
                <div className="loading loading-spinner"></div>
              </div>
            ) : (
              <div className="flex flex-col space-y-2 overflow-y-auto max-h-[66vh]">
                {topics.map((topic, index) => (
                  <button key={index} onClick={()=>handleTopicSelected(topic)} className="flex flex-row p-2 border border-slate-200">
                    {sample_image_url(topic) && <img className="h-20 w-20 object-cover rounded" src={sample_image_url(topic)} alt="avatar" />}
                    <div className="flex flex-col items-start px-2 gap-1" >
                      <h2>{topic.title}</h2>
                      {!topic.hide_content && <p className="line-clamp-1 text-slate-500">{prettifyContent(topic)}</p>}
                      <p className="line-clamp-1 text-slate-500 text-xs">@ {topic.author_nickname}</p>
                    </div>
                  </button>
                ))}
              </div>
            )}
            <div className="text-right space-x-4">
              <Link to={`/broadcasts/create?character_id=${encodeURIComponent(character.id)}`} className="btn btn-sm btn-outline btn-secondary gap-2">
                <i className="fa fa-pencil text-secondary"></i> 创建话题
              </Link>
            </div>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={()=>setVisible(false)}>close</button>
        </form>
      </dialog>
    </>
  );
}