import React, { useEffect, useRef, useState } from "react";
import NavbarContainer from "../NavbarContainer.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../context/UserContext.tsx";
import toast from "react-hot-toast";
import QuestionEntry from "../common/QuestionEntry.tsx";
import Cookies from "js-cookie";
import { useCharacter } from "../context/CharacterContext.tsx";

const questions = [
    {
      id: 'name',
      label: '群聊名称',
      placeHolder: '输入群聊名称',
      maxLen: '30',
    },
]

const UpdateMemberDialog = ({ title, icon, characters, onConfirm }) => {
    const [selectedMembers, setSelectedMembers] = useState<any>([]);
    const ref = useRef<HTMLDialogElement>(null)

    const handleCheckboxChange = (member) => {
        setSelectedMembers((prevSelected) => {
            if (prevSelected.some((m) => m.id === member.id)) {
                return prevSelected.filter((m) => m.id !== member.id);
            } else {
                return [...prevSelected, member];
            }
        });
    };

    const handleAdd = () => {
        onConfirm(selectedMembers);
        setSelectedMembers([]);
        ref.current?.close()
    };

    return (
        <>
            <button className="btn btn-circle" onClick={() => ref.current?.showModal()}>
                <i className={icon}></i>
            </button>
            <dialog className="modal" ref={ref}>
                <div className="modal-box">
                    <h3 className="font-bold text-lg">{title}</h3>
                    <div className="my-4 flex flex-col gap-1 max-h-[60vh] overflow-y-auto">
                        {characters.map((member) => (
                                <div  key={member.id} className="flex flex-row items-center gap-2">
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        value={member.id}
                                        checked={selectedMembers.some((m) => m.id === member.id)}
                                        onChange={() => handleCheckboxChange(member)}
                                    />
                                    <img src={member.avatar_url} alt="avatar" className="w-12 h-12 rounded-full" />
                                    {member.name}
                                </div>
                        ))}
                        {characters.map((member) => (
                                <div  key={member.id} className="flex flex-row items-center gap-2">
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        value={member.id}
                                        checked={selectedMembers.some((m) => m.id === member.id)}
                                        onChange={() => handleCheckboxChange(member)}
                                    />
                                    <img src={member.avatar_url} alt="avatar" className="w-12 h-12 rounded-full" />
                                    {member.name}
                                </div>
                        ))}
                    </div>
                    <button className="btn btn-primary" onClick={handleAdd}>确认</button>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </>
        
    );
};

export default function GroupChatCreatePage() {

    const {groupID} = useParams()

    const navigate = useNavigate()

    const [group, setGroup] = useState<any>({
        name: "",
        members: [],
    })
    const [submitting, setSubmitting] = useState(false)

    const {user} = useUser()
    const {linkedCharacters, fetchLinkedCharacterAsync} = useCharacter()

    useEffect(() => {
        if (user) {
            fetchLinkedCharacterAsync();
        }
    }, [user]);

    const fetchGroup = async (id: string) => {
        setSubmitting(true)
        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/groupchat/${id}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
            }
          });
      
          if (res.status !== 200) {
            toast.error("获取失败🙅");
            return
          }
      
          const data = await res.json();
          setGroup(data["group_chat"])
          setSubmitting(false)
          return
        } catch (e) {
          toast.error("获取失败🙅");
          return
        }
    }

    const submitGroup = async () => {
        if (submitting) {
            return
        }
        setSubmitting(true)

        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/groupchat`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
                body: JSON.stringify(group)
            });
        
            if (res.status !== 200) {
                toast.error("更新失败🙅");
            } else {
                toast.success("保存成功");
                navigate(-1)
            }
        } catch (e) {
            toast.error("更新失败🙅");
        } finally {
            setSubmitting(false)
        }
    }

    useEffect(()=>{
        if (groupID && groupID != "0") {
            fetchGroup(groupID)
        }
    }, [])

    const handleAddMembers = async (newMembers) => {
        const updatedMembers = [
            ...group.members,
            ...newMembers
        ].reduce((unique, member) => {
            if (!unique.some((m) => m.id === member.id)) {
                unique.push(member);
            }
            return unique;
        }, []);
        setGroup({ ...group, members: updatedMembers });
    };

    const handleRemoveMembers = async (membersToRemove) => {
        const updatedMembers = group.members.filter(
            (member) => !membersToRemove.some((m) => m.id === member.id)
        );
        setGroup({ ...group, members: updatedMembers });
    };

    return (
        <NavbarContainer title="群聊设定">
            <div className="flex flex-col p-3 gap-4">
                {
                    questions.map((question, index)=>(
                        <QuestionEntry key={index} question={question} choices={group} setChoices={setGroup}/>
                    ))
                }
                
                <div className="flex flex-wrap gap-2">
                    {
                        group.members.map((member, index)=>(
                            <img className="w-12 h-12 rounded-full" key={index} src={member.avatar_url} />
                        )) 
                    }
                    <UpdateMemberDialog
                        title={"添加成员"}
                        icon="fa-solid fa-plus"
                        characters={linkedCharacters}
                        onConfirm={handleAddMembers}
                    />
                    <UpdateMemberDialog
                        title="移除成员"
                        icon="fa-solid fa-minus"
                        characters={group.members}
                        onConfirm={handleRemoveMembers}
                    />
                </div>
                <button
                    className="btn btn-primary mt-4 max-w-md w-full"
                    disabled={submitting || group.name == ""}
                    onClick={submitGroup}
                >
                    保存
                </button>
            </div>
        </NavbarContainer>
    )
}