import React, { useEffect } from "react";
import { ContentItemInfo } from "./ContentBrowse.tsx";
import CommentSection from "../common/CommentSection.tsx";


export default function ContentComments({ content }) {

    return (
        <CommentSection header={<ContentItemInfo item={content}/>} commentsEndpoint={`content/${content.id}`}/>
    )
}