import React, {useState, useEffect, useRef} from "react";
import { Link, useParams } from "react-router-dom";
import ContentBrowse from "../content/ContentBrowse.tsx";
import toast from "react-hot-toast";
import CharacterBrowse from "../character/CharacterBrowse.tsx";
import NavbarContainer from "../NavbarContainer.tsx";
import UserAvatar from "../common/UserAvatar.tsx";

const tabs = [
    { name: '角色' },
    { name: '分享' },
];

function MyCharacters({user_id}) {
    return (
        <CharacterBrowse sorting="combined" prefix={`character-browse-combined-public-${user_id}`} authed={true} user_id={user_id}/>
    )
}

export default function PublicProfilePage() {

    const {user_id} = useParams()

    const [user, setUser] = useState({
        user_id: user_id,
        nickname: "",
        username: "",
    })
    const [loading, setLoading] = useState(false)

    const fetchUserAsync = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/users/${user_id}/info`, {
                method: 'GET',
            });
            const data = await res.json();
            setUser(data)
        } catch (error) {
            toast.error("获取话题失败🙅");
        }
    }

    useEffect(()=>{
        fetchUserAsync()
    }, [])

    const [activeTab, setActiveTab] = useState(tabs[0].name);    

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <NavbarContainer>
                <div className="flex flex-col px-3">
                    <div className="card card-side">
                        <div className="card-body">
                            {user.username && <div className="flex flex-row space-x-2">
                                <UserAvatar user={user} size={16}/>
                                <h2 className="card-title text-xl">{user.nickname}</h2>
                            </div>}
                        </div>
                    </div>
                    <div role="tablist" className="tabs tabs-bordered py-4">
                        {tabs.map((tab) => (
                            <a
                                key={tab.name}
                                role="tab"
                                className={`tab ${activeTab === tab.name ? 'tab-active' : ''}`}
                                onClick={() => handleTabClick(tab.name)}
                            >
                                {tab.name}
                            </a>
                        ))}
                    </div>
                    {activeTab === tabs[0].name && <MyCharacters user_id={user_id}/>}
                    {activeTab === tabs[1].name && <ContentBrowse user_id={user_id} prefix={`content-browse-${user_id}`} character_id={undefined} />}
                </div>
        </NavbarContainer>
    )
}