import React from 'react';
import { Link } from 'react-router-dom';
import ImageWithSkeleton from '../common/ImageWithSkeleton.tsx';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie'
import InfinitePullToRefreshScroll from '../common/InfiniteScroller.tsx';


const pageSize = 20

function CharacterBrowse({ sorting, prefix, authed, user_id, gender="", children=null }) {
    const bearer = authed ? Cookies.get('ringriseusertoken'): "fake"
    const fetchItems = async (page: number) => {
        const body = {
            "sorting": sorting,
            "page_size": pageSize,
            "page": page,
            "gender": gender,
        }
        if (user_id) {
            body["user_id"] = user_id
        }
        const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/list`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${bearer}`,
            },
            body: JSON.stringify(body)
        });

        if (res.status !== 200) {
            toast.error("获取角色失败🙅");
            return
        }

        const data = await res.json();
        const items = data["characters"]
        return items
    }



    return (
        <InfinitePullToRefreshScroll prefix={prefix} fetchItems={fetchItems}>
            {({ items }) => (
                <>
                    {children}
                    {/* Render your character items */}
                    {items.map((character, index) => (
                        <div key={index} className="p-1 w-1/2 md:w-1/3 lg:w-1/6">
                            <Link to={`/character/${character.id}`} className="relative flex flex-col rounded-lg h-full overflow-hidden justify-end" style={{ paddingTop: '133%' }}>
                                <div className="absolute inset-0">
                                    <ImageWithSkeleton className="w-full h-full object-cover" src={character.portrait_url} alt={`Avatar of ${character.name}`} />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent bg-gradient-[rgba(0,0,0,0.7),rgba(0,0,0,0.35)_50%,rgba(0,0,0,0)_100%]"></div>
                                </div>
                                <div className="absolute mt-auto p-2">
                                    <div className="text-xs line-clamp-1 py-1 text-white">
                                        <b>{character.name}</b>
                                    </div>
                                    <div className="text-xs line-clamp-3 text-slate-300 h-12">
                                        {character.bio}
                                    </div>
                                    <div className="text-slate-300 text-xs">
                                        {character.public ? <><i className="fa-regular fa-comment-dots"></i> {character.count}</> : "私密"}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </>
            )}
        </InfinitePullToRefreshScroll>
    );
};

export default CharacterBrowse;