import React from "react";
import Zoom from "react-medium-image-zoom"

export default function UserAvatar({user, size, enableZoom=true}) {
    if (!user) return null

    if (user.avatar) {
        if (enableZoom) {
            return <Zoom><img className={`prose rounded-full w-${size} h-${size}`} src={user.avatar} alt="user avatar" /></Zoom>
        }
        return  <img className={`prose rounded-full w-${size} h-${size}`} src={user.avatar} alt="user avatar" />
    }
    const seed = user?.username
    return (
        <img className={`rounded-full prose w-${size} h-${size}`} src={`https://api.dicebear.com/8.x/shapes/svg?seed=${encodeURIComponent(seed)}`} alt="user avatar" />
    )
}