import React, { useState } from "react";
import BiliBiliPlayer from "./BilibiliPlayer.tsx";

export default function ImageWithSkeleton({ src, alt, ...props }) {
    // State to keep track of whether the image has loaded
    const [loaded, setLoaded] = useState(false);
  
    return (
      <div className={props.className}>
        {/* Skeleton Loader */}
        {!loaded && <div className="skeleton w-full h-full"></div>}
        
        {/* Actual Image */}
        <img
          src={src}
          alt={alt}
          {...props}
          style={{ display: loaded ? 'block' : 'none' }}
          onLoad={() => setLoaded(true)}
          className={`${props.className + (loaded ? ' object-cover' : ' hidden')}`}
        />
      </div>
    );
};