import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../context/UserContext.tsx";
import NavbarContainer from "../NavbarContainer.tsx";
import toast from "react-hot-toast";
import { SKU } from "../utils/type.ts";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

interface SKUProps {
    sku: SKU
}

function CoinPaymentBox({ sku }: SKUProps) {

    return (
        <Link to={`/payment?sku_id=${encodeURIComponent(sku.id)}`} className="btn btn-outline flex flex-col text-xl w-full max-w-xs">
            <div className="flex flex-row items-center gap-2">
                <i className="fa-solid fa-coins" />
                <div className="w-full text-end">
                    {sku.name}
                </div>
            </div>
            <div className="flex flex-row items-center gap-2">
                ¥{sku.price}
            </div>
        </Link>
    )
}

function SubPaymentBox({ sku }: SKUProps) {

    return (
        <Link to={`/payment?sku_id=${encodeURIComponent(sku.id)}`} className="btn btn-outline btn-lg flex flex-col text-xl w-full max-w-xs">
            <div className="flex flex-col items-start">
                <p>{sku.name}</p>
                <p className="text-sm text-slate-400">{sku.description}</p>
            </div>
            <div className="flex flex-row items-center gap-2">
                ¥{sku.price}
            </div>
        </Link>
    )
}

export default function CoinPage() {

    const { user, fetchUserAsync } = useUser()
    const [coinSKUs, setCoinSkus] = useState<SKU[]>()
    const [subSKUs, setSubSkus] = useState<SKU[]>()
    const [sub, setSub] = useState(null)

    const fetchSKUs = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/payment/skus/coin`, {
                method: "GET",
            })
            const skus = await res.json()
            setCoinSkus(skus)
        } catch (e) {
            toast.error("暂时无法获取购买选项")
        }
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/payment/skus/sub`, {
                method: "GET",
            })
            const skus = await res.json()
            setSubSkus(skus)
        } catch (e) {
            toast.error("暂时无法获取购买选项")
        }
    }

    const fetchSub = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/user/subscription`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                    'Content-Type': 'application/json',
                },
            })
            const data = await res.json()
            setSub(data["sub"])
        } catch (e) {
            toast.error("暂时无法获取购买选项")
        }
    }

    useEffect(() => {
        fetchSKUs()
        fetchUserAsync()
        fetchSub()
    }, [])

    const infoBox = (title: string, value: number | undefined) => (
        <div className="bg-base-100 w-full p-4 max-w-xs rounded flex flex-col">
            {title}
            <div className="flex flex-row items-center gap-2">
                <i className="fa-solid fa-coins" />
                {value}
            </div>
        </div>
    )

    function utcToLocalDateString(utcDateString) {
        // Parse the UTC date string into a Date object
        const date = new Date(utcDateString+"Z");
    
        // Get local year, month, and day
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
    
        // Construct and return the local date string in YYYY-MM-DD format
        return `${year}-${month}-${day}`;
    }

    const subBox = () => (
        <div className="bg-base-100 w-full p-4 max-w-xs rounded flex flex-col">
            {
                (sub === null) && <span className="loading loading-spinner"></span>
            }
            {
                (sub === "") && <div>月卡未激活</div>
            }
            {
                (sub && sub["type"]) && <div>
                    <div className="text-sm">{sub["type"] === "unlimited"?"全免月卡（生效中）":"基础月卡（生效中）"}</div>
                    <div>{`${utcToLocalDateString(sub["start_date"])} -> ${utcToLocalDateString(sub["end_date"])}`}</div>
                </div>
            }
        </div>
    )

    return (
        <NavbarContainer title="我的钱包">
            <div className="flex flex-col p-4 gap-4">
                {infoBox("余额", user?.coin)}
                {
                    !coinSKUs ?
                        <span className="loading loading-sm"></span> :
                        coinSKUs.map((sku, index) => (
                            <CoinPaymentBox sku={sku} key={index} />
                        ))
                }
                {subBox()}
                {
                    !subSKUs ?
                        <span className="loading loading-sm"></span> :
                        subSKUs.map((sku, index) => (
                            <SubPaymentBox sku={sku} key={index} />
                        ))
                }
                <div className="text-slate-400 p-2">#有月卡时购买会替换当前月卡</div>
            </div>
        </NavbarContainer>
    )
}