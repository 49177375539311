import { useEffect, useState } from "react";
import { createBroadcastTopic, fetchCharacterAPI, uploadResource } from "../utils/api.ts";
import Cookies from "js-cookie";
import React from "react";
import NavbarContainer from "../NavbarContainer.tsx";
import { useUser } from "../context/UserContext.tsx";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import ImageGenerator from "../common/ImageGenerator.tsx";
import QuestionEntry from "../common/QuestionEntry.tsx";
import { prettifyContent } from "../utils/content.ts";
import { Character } from "../utils/type.ts";
import { parseImageUrlsFromMarkdown, removeImageUrlsFromMarkdown } from "../utils/markdown.ts";
import BiliBiliPlayer from "../common/BilibiliPlayer.tsx";

const tabs = [
  { name: '图片' },
  { name: '视频' },
];

const questions = [
  {
    id: 'title',
    label: '标题',
    placeHolder: '话题的标题',
    maxLen: '20',
  },
  {
    id: 'content',
    label: '设定',
    placeHolder: '话题的设定',
    maxLen: '1000',
    textArea: true,
  },
  {
    id: 'greeting',
    label: '开场白',
    placeHolder: '聊这个话题时的开场白',
    maxLen: '300',
    textArea: true,
  },
]

export default function BroadcastCreatePage() {
  const { user, loading } = useUser();
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [topic, setTopic] = useState({
    id: 0,
    character_id: "",
    title: "",
    author_id: 0,
    public: false,
    content: "",
    greeting: "",
    hide_content: false,
  })
  const [character, setCharacter] = useState<null | Character>(null)

  const [searchParams, setSearchParams] = useSearchParams();
  const search_param_topic_id = Number(searchParams.get('topic_id'))
  const search_param_character_id = searchParams.get('character_id')

  const [imagePrompt, setImagePrompt] = useState<string>("")
  const [imageSrc, setImageSrc] = useState("")
  const [videoSrc, setVideoSrc] = useState("")
  const [activeTab, setActiveTab] = useState(tabs[0].name)

  const fetchBroadcastAndCharacter = async (topic_id: number) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/broadcast/topics/${topic_id}`, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
        },
      });
      const data = await res.json();
      setTopic({
        id: data["id"],
        title: data["title"],
        author_id: data["author_id"],
        public: data["public"],
        character_id: data["character_id"],
        content: data["content"],
        greeting: removeImageUrlsFromMarkdown(data["greeting"] || "").trim(),
        hide_content: data["hide_content"] || false,
      })
      const urls = parseImageUrlsFromMarkdown(data["greeting"])
      if (urls && urls.length) {
        if (urls[0].startsWith("BV")) {
          setActiveTab(tabs[1].name)
          setVideoSrc(urls[0])
        } else {
          setImageSrc(urls[0])
        }
      }
      
      // fetch character
      if (data["character_id"]) {
        const character = await fetchCharacterAPI(data["character_id"])
        setCharacter(character)
      }

    } catch (error) {
      toast.error("话题未公开")
      return
    }
  }

  const fetchCharacter = async (character_id: string) => {
    try {
      const character = await fetchCharacterAPI(character_id)
      setCharacter(character)
    } catch (error) {
      toast.error("参数错误")
      return
    }
  }

  useEffect(() => {
    if (search_param_topic_id) {
      fetchBroadcastAndCharacter(search_param_topic_id)
    } else if (search_param_character_id) {
      // fetch character
      if (search_param_character_id) {
        fetchCharacter(search_param_character_id)
      }
    }
  }, [user]);

  const create_topic_valid = !!topic.title && topic.content && topic.greeting

  const confirmCreateBroadcast = async () => {
    if (!create_topic_valid) return false

    setConfirmLoading(true)

    var imageURL = imageSrc
    if (imageSrc.startsWith("blob:")) {
      let blob = await (await fetch(imageSrc)).blob();
      let fileName = "image";
      let file = new File([blob], fileName, { type: blob.type });
      const serverSrc = await uploadResource(file, () => { })
      if (!serverSrc) {
        setConfirmLoading(false)
        return
      }
      imageURL = serverSrc
      setImageSrc(imageURL)
    }

    var greeting = topic.greeting
    if (imageURL && activeTab ===tabs[0].name) {
      greeting = `![](${imageURL})\n${greeting}`
    }
    if (videoSrc && activeTab ===tabs[1].name) {
      greeting = `![](${videoSrc})\n${greeting}`
    }
    const character_id = topic.character_id || search_param_character_id
    const id = await createBroadcastTopic(topic.id, topic.title, topic.content, greeting, topic.public, topic.hide_content, character_id)
    setConfirmLoading(false)
    if (id) {
      setTopic({...topic, "id": id})
    }
    toast.success("保存成功")
  }

  const onComplete = (prompt: string, src: string) => {
    setImagePrompt(prompt)
    setImageSrc(src)
  }

  const characterSection = () => (
    <div className="flex flex-row items-center gap-2 p-2 rounded-lg bg-slate-200 w-full max-w-md" >
      <img className="w-12 h-12 rounded-full" src={character?.avatar_url} />
      <p>{character?.name}</p>
    </div>
  )

  const videoCreator = () => (
    <div className="w-full max-w-md">
      <div className="label">BV号</div>
      <input className="textarea" value={videoSrc} onChange={(e)=>setVideoSrc(e.target.value)} placeholder="BV1Eu4y1B7EE" />
      {videoSrc && <BiliBiliPlayer bv={videoSrc} />}
    </div>
  )

  const hideContentChecker = () => (
    <div className="flex flex-row gap-2 py-2">
        <input type="checkbox" checked={topic.hide_content} onClick={()=>setTopic({...topic, hide_content: !topic.hide_content})} className="checkbox checkbox-secondary" />
        <p>隐藏设定</p>
    </div>
  )

  const publicChecker = () => (
    <div className="flex flex-row gap-2 py-2">
        <input type="checkbox" checked={topic.public} onClick={()=>setTopic({...topic, public: !topic.public})} className="checkbox checkbox-secondary" />
        <p>公开话题</p>
    </div>
  )

  return (
    <NavbarContainer title={topic.id == 0 ? "创建话题": "编辑话题"}>
      <div className="p-4 space-y-4 flex flex-col">
        {character && characterSection()}

        <div role="tablist" className="tabs tabs-bordered w-full max-w-md">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              role="tab"
              className={`tab ${activeTab === tab.name ? 'tab-active' : ''}`}
              onClick={() => setActiveTab(tab.name)}
            >
              {tab.name}
            </a>
          ))}
        </div>
        {activeTab === tabs[0].name && <ImageGenerator placeholder="添加图片" prompt={imagePrompt} src={imageSrc} reference={character?.avatar_url} onComplete={onComplete} />}
        {activeTab === tabs[1].name && videoCreator()}
        {
          questions.map((question, index)=>(
            <QuestionEntry key={index} question={question} choices={topic} setChoices={setTopic}/>
          ))
        }
        {hideContentChecker()}
        {publicChecker()}
        <button className="btn btn-primary w-16" onClick={confirmCreateBroadcast} disabled={!create_topic_valid || confirmLoading}>保存</button>
      </div>
    </NavbarContainer>
  );
}