import React, { useEffect, useRef, useState } from 'react';
import ContentBrowse from './ContentBrowse.tsx';
import ContentScroll from './ContentScroll.tsx';
import { Link } from 'react-router-dom';

export default function ContentListPage() {
    
    const [scrollMode, setScrollMode]  = useState(() => {
        const saved = localStorage.getItem('scrollMode');
        return saved !== null ? JSON.parse(saved) : true;
    });

    useEffect(() => {
        localStorage.setItem('scrollMode', JSON.stringify(scrollMode));
    }, [scrollMode]);

    const navbar = () => {
        return (
            <div className="navbar absolute left-0 top-0 w-full z-[99]">
                <div className="navbar-start">
                </div>
                <div className="navbar-center">
                </div>
                <div className="navbar-end">
                    <button className="pe-2" onClick={()=>setScrollMode(!scrollMode)}>
                        <i className={(scrollMode?"fa-solid fa-list ":"fa-solid fa-image-portrait ") + (scrollMode?"text-white":"")} />
                    </button>
                </div>
            </div>
        )
    }

    const normalHome = () => (
        <div className="pb-32 pt-16 md:w-1/2">
            <div className="navbar fixed top-0 left-0 w-full z-[99] bg-base-100">
                <div className="flex-1"></div>
                <div className="navbar-end">
                    <Link to="/content/it/create" className="btn btn-ghost">
                        发帖
                    </Link>
                </div>
            </div>
            <div className="flex flex-col items-center h-full">
                <div className="flex flex-col w-full">
                    <div className="flex flex-col py-4 pt-0">
                        <ContentBrowse user_id={undefined} character_id={undefined} />
                    </div>
                </div>
            </div>
        </div>
    )

    const scrollHome = () => (
        <div className="h-full w-full flex flex-col items-center">
            <ContentScroll />
        </div>
    )

    return (
        <div className="h-full flex flex-col items-center">
            {normalHome()}
        </div>
    )
};