import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as AppIcon } from './icon-app.svg'
import Cookies from "js-cookie"
import CharacterListPage from "../character/CharacterListPage.tsx";

export default function HomePage() {

    const [hasNewNotification, setHasNewNotification] = useState(false)

    useEffect(()=> {
        fetchNewNotification()
    }, [])


    const fetchNewNotification = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/user/notification/comment`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json()
            if (data["comments"] && data["comments"].length > 0) {
                const current_ts = data["comments"][0]["time_created"]
                const local_comment_ts = localStorage.getItem('latest_related_comment_ts')
                if (current_ts != local_comment_ts) {
                    setHasNewNotification(true)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const navbar = () => {
        return (
            <div className="navbar absolute top-0 left-0 w-full z-[99]">
                <div className="navbar-start">
                    <button className="btn btn-ghost text-lg md:hidden">
                        <div className={`flex flex-col items-start`} >
                            <h1>次元通讯</h1>
                            <p className="text-xs">DCall.chat</p>
                        </div>
                    </button>
                </div>
                <div className="navbar-center">
                </div>
                <div className="navbar-end">
                    <Link to="/search" className="btn btn-ghost relative">
                            <i className="fa-solid fa-search" />
                    </Link>
                    <Link to="/notification" className="btn btn-ghost relative">
                        <i className="fa-solid fa-envelope" />
                        {hasNewNotification && <span className="absolute top-3 right-3 w-2 h-2 bg-red-500 rounded-full"></span>}
                    </Link>
                    <Link to="https://bwbbsvqza3t.feishu.cn/wiki/MwPmwiB8pizBoqkCMMPccUYtnhc">
                        <AppIcon className={`px-4 w-[54px] h-[54px] fill-current`} />
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <div className="h-full flex flex-col items-center">
            {navbar()}
            {<CharacterListPage />}
        </div>
    )
}
