import React, { useEffect, useRef, useState } from 'react';
import { useCharacter } from '../context/CharacterContext.tsx';
import { prettifyContent } from '../utils/content.ts';
import SwipeToDelete from 'react-swipe-to-delete-ios';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { dateToText } from '../utils/date.js';
import Markdown from '../common/markdown.tsx';
import { removeImageUrlsFromMarkdown } from '../utils/markdown.ts';

interface ChatItem {
    is_group_chat: boolean
    id: string
    name: string
    avatar: string
    lastMessage: string
    date: Date | null
}

interface ChatItemProps {
    item: ChatItem
}

function ChatEntry({item}: ChatItemProps) {

    const historyLSID = `history-${item.id}`

    const deleteChat = async (e) => {
        if (item.is_group_chat) {
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/groupchat/${item.id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                    }
                })
                if (res.status == 200) {
                    localStorage.removeItem(historyLSID);
                }
            } catch (e) {
                return
            }
        } else {
            try {
                await fetch(`${process.env.REACT_APP_API_URL}/characters/${item.id}/history/bulkdelete`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                    }
                })
                localStorage.removeItem(historyLSID);
            } catch (e) {
                return
            }
        }
    }

    const chatLink = () => {
        if (item.is_group_chat) {
            return `/gc/${item.id}`
        }
        return `/c/${item.id}`
    }

    return (
            <SwipeToDelete onDelete={deleteChat} height={80} deleteText='删除'>
                <Link to={chatLink()} className="bg-base-100 flex flex-row p-2 border-b-[1px] border-slate-200">
                    <img className="w-16 h-16 rounded-full" src={item.avatar} alt={`Avatar of ${item.name}`} />
                    <div className="flex flex-col p-2 w-full">
                        <div className='flex flex-row justify-between items-center w-full'>
                            <div className="line-clamp-1 text-lg">
                                <b>{item.name}</b>
                            </div>
                            <div className="line-clamp-1 text-sm text-slate-500">
                                {item.date ? dateToText(item.date): ""}
                            </div>
                        </div>
                        <div className="line-clamp-1 w-full">
                            <Markdown>{removeImageUrlsFromMarkdown(item.lastMessage) || " "}</Markdown>
                        </div>
                    </div>
                </Link>
            </SwipeToDelete>
    )
}

export default function CharacterChats() {

    const {linkedCharacters, fetchLinkedCharacterAsync, groups, fetchGroupsAsync} = useCharacter()

    useEffect(()=> {
        fetchLinkedCharacterAsync()
        fetchGroupsAsync();
    }, [])

    const lastLocalMessage = (id) => {
        const historyLSID = `history-${id}`
        const localHistory = localStorage.getItem(historyLSID);
        const history = localHistory !== null ? JSON.parse(localHistory) : [{"content":""}];
        if (history && history.length) {
            const message = history[history.length - 1]
            return {
                "content": prettifyContent(message),
                "date": new Date(message.date)
            }
        }
        return {
            "content": "",
            "date": null,
        }
    }

    const getChatItems = () => {
        var result: ChatItem[] = []
        for (let character of linkedCharacters) {
            const message = lastLocalMessage(character.id)
            result.push({
                is_group_chat: false,
                id: character.id, 
                name: character.name,
                avatar: character.avatar_url,
                lastMessage: message.content,
                date: message.date,
            })
        }

        for (let group of groups) {
            const message = lastLocalMessage(group.id)
            result.push({
                is_group_chat: true,
                id: group.id, 
                name: `${group.name}(${group.member_count + 1})`,
                avatar: `https://api.dicebear.com/8.x/shapes/svg?seed=${encodeURIComponent(group.id)}`,
                lastMessage: message.content,
                date: message.date,
            })
        }

        result.sort((a, b) => {
            if (a.date === null) return 1;
            if (b.date === null) return -1;
            return b.date.getTime() - a.date.getTime();
        });
        return result
    }

    const navbar = () => {
        return (
            <div className="navbar fixed left-0 top-0 w-full z-[999] bg-base-100">
                <div className="navbar-start">
                </div>
                <div className="navbar-center gap-1 w-1/2 justify-center">
                </div>
                <div className="navbar-end">
                    <Link to="/groupchat/create/0" className="btn btn-ghost">创建群聊</Link>
                </div>
            </div>
        )
    }

    return (
        <div className="h-full max-w-[100vw] overflow-y-auto no-scrollbar">
            {navbar()}
            <div className="pb-32 pt-[64px]">
                <div className="flex flex-col w-full">
                    {getChatItems().map((item) => (
                        <div className="w-full" key={item.id}>
                           <ChatEntry item={item} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};