import { useState, useEffect, useRef } from "react";
import { toast } from "react-hot-toast"
import Cookies from 'js-cookie';
import { Howl } from 'howler';
import { agentChat, cookCharacterBroadcast, deleteCharacterEvent, editCharacterEvent, fetchCharacterAPI } from "../utils/api.ts";
import { Character, GroupChat } from "../utils/type.ts";

function useGroupBot(groupID: string) {

    const historyLSID = `history-${groupID}`
    const [history, setHistory] = useState<Array<Object> | null>(() => {
        const localHistory = localStorage.getItem(historyLSID);
        return localHistory !== null ? JSON.parse(localHistory) : null;
    });
    useEffect(() => {
        if (history?.constructor === Array) {
            localStorage.setItem(historyLSID, JSON.stringify(history));
        }
    }, [history]);

    const groupLSID = `group-${groupID}`
    const [group, setGroup] = useState<GroupChat | null>(() => {
        const localGroup = localStorage.getItem(groupLSID);
        return localGroup !== null ? JSON.parse(localGroup) : null;
    });
    useEffect(() => {
        localStorage.setItem(groupLSID, JSON.stringify(group));
    }, [group]);

    const [processing, setProcessing] = useState(false)

    const fetchHistory = async (group_chat_id: string) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/groupchat/${group_chat_id}/history?limit=100`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });

            const data = await res.json();
            if (data.constructor === Array) {
                setHistory(data)
            }
        } catch (e) {
            console.error(e);
        }
    };

    const fetchGroup = async (id: string) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/groupchat/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                }
            });

            if (res.status !== 200) {
                toast.error("获取失败🙅");
                return
            }

            const data = await res.json();
            setGroup(data["group_chat"])
            return
        } catch (e) {
            toast.error("获取失败🙅");
            return
        }
    }

    // Run once on initialization
    useEffect(() => {
        fetchGroup(groupID);
        fetchHistory(groupID);
    }, []);

    const userText = async (text: string, model: number) => {
        if (processing || history === null) {
            return
        }

        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/agent/groupchat`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
                body: JSON.stringify({
                    "message": {
                        "role": "user",
                        "content": text,
                    },
                    "model": model,
                    "groupchat_id": groupID,
                }),
            })
            const status = await res.status;
            if (status != 200) {
              if (status == 401) {
                toast.error("没有登录🙅")
              } else {
                toast.error("无法发送🙅")
              }
              return
            }
            const data = await res.json()
            setHistory([...history, {
                "id": data["event_id"],
                "role": "user",
                "content": text,
                "date": Date.now(),
                "type": "groupchat",
            }])
          } catch (e) {
            toast.error("暂时无法发送🙅")
            return
        }
    }

    const send = async (character_id: string, model: number) => {
        if (processing || history === null) {
            return
        }
        setProcessing(true)
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/agent/groupchat`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
                body: JSON.stringify({
                    "model": model,
                    "groupchat_id": groupID,
                    "character_id": character_id,
                }),
            })
            const status = await res.status;
            if (status != 200) {
              if (status == 401) {
                toast.error("没有登录🙅")
              } else if (status == 429) {
                toast.error("当天聊天额度已满，请之后再来聊吧")
              } else if (status == 500) {
                toast.error("余额不足")
              } else {
                toast.error("无法发送🙅")
              }
              setProcessing(false)
              return
            }
            const data = await res.json()
            setHistory(prevHistory => [...prevHistory || [], {
                "id": data["event_id"],
                "role": "assistant",
                "content": data["reply"],
                "date": Date.now(),
                "type": "groupchat",
                "character_id": character_id,
            }])
            setProcessing(false)
          } catch (e) {
            toast.error("暂时无法发送🙅")
            setProcessing(false)
            return
        }
    }

    const clearHistory = async (groupID) => {
        try {
            await fetch(`${process.env.REACT_APP_API_URL}/groupchat/${groupID}/history/bulkdelete`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                }
            })
            setHistory([])
        } catch (e) {
            console.log(e)
            toast.error("Failed")
            return
        }
    }

    const deleteEvent = async (event_id: number) => {

        console.log("delete")
        if (!history || processing) return

        setProcessing(true)
        setHistory(prev => (prev || []).filter(item => item["id"] !== event_id))
        const _ = await deleteCharacterEvent(event_id)
        setProcessing(false)
    }

    const regenEvent = async (character_id: string, event_id: number, model: number) => {
        if (!history || processing) return

        const first = history.find(item => item["id"] == event_id)
        if (!first) return

        const _ = await deleteEvent(event_id)
        send(character_id, model)
    }

    const editEvent = async (event_id: number, content: string) => {
        if (!history || processing) return
        const success = await editCharacterEvent(event_id, content)
        if (!success) {
            toast.error("修改失败，请稍后再试")
            return
        }
        setHistory(prev => {
            const newHistory = [...prev || []]
            newHistory[newHistory.length - 1] = {
                ...newHistory[newHistory.length - 1],
                "content": content,
                "date": Date.now(),
            }
            return newHistory
        });
    }

    const cookAIText = async (character_id: string, topic_id: number, rewrite: boolean) => {
        setProcessing(true)
        const result = await cookCharacterBroadcast(character_id, topic_id, groupID)
        if (result) {
          console.log(result)
          var tmpHistory = [...(history || [])]
          for (let message of result["response"]) {
            tmpHistory.push({
              "id": result["event_id"],
              "type": "broadcast",
              "role": message["role"],
              "content": message["content"],
              "character_id": character_id,
              "date": Date.now(),
              "hide": message["hide"],
              "broadcast_id": result["broadcast_id"],
            })
            setHistory(tmpHistory);
          }
        } else {
          toast.error("生成失败，请稍后再试");
          setProcessing(false)
          return false
        }
        setProcessing(false)
        return true
      }

    return {
        processing,
        group,
        history,
        userText,
        send,
        deleteEvent,
        regenEvent,
        editEvent,
        clearHistory,
        cookAIText,
    };
}


export default useGroupBot;
