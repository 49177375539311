import toast from "react-hot-toast";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, } from "react-router-dom";
import NavbarContainer from "../NavbarContainer.tsx";
import { Character } from "../utils/type.ts";
import { fetchCharacterAPI } from "../utils/api.ts";
import LoadingPage from "../LoadingPage.tsx";
import { useUser } from "../context/UserContext.tsx";
import Markdown from "../common/markdown.tsx";
import { Sheet } from 'react-modal-sheet';
import { parseImageUrlsFromMarkdown, removeImageUrlsFromMarkdown } from "../utils/markdown.ts";
import ContentComments from "./ContentComments.tsx";
import { replaceSensitiveWords } from "../utils/censor/censor.ts";
import { useSwiper, useSwiperSlide } from "swiper/react";
import "./ContentPage.css"

interface Dialog {
    name: string;
    dialog: string;
}

interface ContentSectionProps {
    content: any;
    setContent: any;
    character: {
        name: string
        portrait_url: string;
        bio: string
    };
    liked: boolean
    audioRef: React.RefObject<HTMLAudioElement>
    children?: React.ReactNode;
}

const colors = ['text-white', 'text-blue-300', 'text-red-300', 'text-green-300', 'text-yellow-300', 'text-orange-300'];

const getFirstDialog = (dialogs) => {
    return removeImageUrlsFromMarkdown(dialogs[0].dialog)
}

const getDistinctNames = (dialogs) => {
    const seenNames = new Set();
    return dialogs.reduce((distinctNames, dialog) => {
        if (!seenNames.has(dialog.name)) {
            seenNames.add(dialog.name);
            distinctNames.push(dialog.name);
        }
        return distinctNames;
    }, []);
};

const splitLongDialogs = (dialogs: Dialog[]) => {
    const maxLength = 80;
    return dialogs.flatMap(dialog => {
        if (dialog.dialog.length <= maxLength) {
            return dialog;
        }

        const lines = dialog.dialog.split('\n\n');
        return lines.filter(line => line.trim().length > 0).flatMap(line => {
            return { ...dialog, dialog: line };
        });
    });
};



const sendContentView = async (content_id: number, consumedUnit: number, totalUnit: number, action: string) => {

    const MAX_RECENT_CONTENT_IDS = 10

    let recentContentIds = JSON.parse(localStorage.getItem('recentContentIds') || '[]');
    if (recentContentIds.includes(content_id)) {
        console.log('Content view already sent recently, skipping...');
        return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/content/${content_id}/view`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('ringriseusertoken')}`
            },
            body: JSON.stringify({
                consumed_unit: consumedUnit,
                total_unit: totalUnit,
                next_action: action
            })
        });
        const data = await response.json();
        console.log(data.message);

        // Update recentContentIds in localStorage
        recentContentIds.push(content_id);
        if (recentContentIds.length > MAX_RECENT_CONTENT_IDS) {
            recentContentIds = recentContentIds.slice(-MAX_RECENT_CONTENT_IDS);
        }
        localStorage.setItem('recentContentIds', JSON.stringify(recentContentIds));
    } catch (error) {
        console.error('Error sending content view:', error);
    }
};

const UISection = ({ character, content, setContent, sendContentView, userLiked }) => {

    const [localLiked, setLocalLiked] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const {user} = useUser()


    const like = async () => {
        if (loading) return
        if (userLiked || localLiked) {
            toast("已经赞过了")
            return
        }
        setLoading(true)
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/content/${content.id}/like`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                    'Content-Type': 'application/json',
                },
            });
            if (res.status === 401) {
                navigate("/login")
                return
            }
            const data = await res.json()
            if (data["likes"]) {
                setContent(prevContent => ({
                    ...prevContent,
                    likes: prevContent["likes"] + 1
                }));
                setLocalLiked(true)
                toast.success("点赞成功")
            }
            setLoading(false)
        } catch (error) {
            toast.error("点赞失败")
            setLoading(false)
        }
    }

    const gotoCharacter = () => {
        if (character?.public) {
            sendContentView()
            navigate(`/character/${character?.id}`)
        }
    }

    const characterSection = () => (
        <button onClick={gotoCharacter} className="pt-4" >
            <img className="w-12 h-12 max-w-prose rounded-full" src={character?.avatar_url} />
        </button>
    )


    return (
        <>
            <Sheet detent="content-height" className="w-full md:w-1/2 justify-self-center" isOpen={isOpen} onClose={() => setOpen(false)}>
                <Sheet.Container>
                    <Sheet.Header className="bg-base-100" />
                    <Sheet.Content className="bg-base-100"><Sheet.Scroller><ContentComments content={content} /></Sheet.Scroller></Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop />
            </Sheet>
            <div className="flex flex-col gap-1 m-4 w-12">
                {content["user_name"] === user?.username &&
                    <button className="h-16 flex flex-col justify-start items-center" onClick={() => navigate(`/content/create`, {
                        state: {
                            "value": content["content"],
                            "character_id": character.id,
                            "content_id": content.id,
                            "title_value": content["title"],
                            "description_value": content["description"],
                            "cover_value": content["cover"],
                            "bgm_value": content["bgm"],
                        }
                    })}>
                        <i className="fa-solid fa-edit text-3xl text-white"></i>
                    </button>
                }
                <button className={`h-16 flex flex-col items-center`} onClick={() => setOpen(true)}>
                    <i className="fa-solid fa-comment-dots text-3xl text-white"></i> <p className="text-white">{content["comments"] ? content["comments"] : ""}</p>
                </button>
                <button className={`h-16 flex flex-col items-center ${(userLiked || localLiked) ? "text-red-400" : "text-white"}`} disabled={loading} onClick={like}>
                    <i className="fa-solid fa-thumbs-up text-3xl"></i> <p>{content["likes"] ? content["likes"] : ""}</p>
                </button>
                {character && character.public && characterSection()}
            </div>
        </>
    )
}

const ZoomImage = ({ bgSrc }) => {
    const isGif = bgSrc.endsWith('.gif');
  
    return (
      <div className="relative inset-0 w-full h-full overflow-hidden">
        <img
          src={bgSrc}
          alt="Background"
          className={`absolute inset-0 w-full h-full object-cover ${!isGif ? 'zoom-animation' : ''}`}
        />
      </div>
    );
  };

const ContentSection: React.FC<ContentSectionProps> = ({ content, setContent, character, liked, audioRef }) => {
    const dialogs: Dialog[] = splitLongDialogs(JSON.parse(content["content"]));
    const names = getDistinctNames(dialogs);
    const [currentDialogIndex, setCurrentDialogIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState(getFirstDialog(dialogs));
    const [bgSrc, setBgSrc] = useState(character.portrait_url);
    const [completed, setCompleted] = useState(false)

    const consumedUnit = () => (completed ? dialogs.length : currentDialogIndex + 1)

    const swiperSlide = useSwiperSlide() || { isActive: false, standalone: true };
    useEffect(() => {
        if (swiperSlide.isActive) {
            restart()
        }
        if (!swiperSlide.isActive && !swiperSlide.standalone) {
            sendContentView(content.id, consumedUnit(), dialogs.length, "next")
        }
        if (swiperSlide.standalone) {
            return () => {
                console.log("EXIT")
                sendContentView(content.id, consumedUnit(), dialogs.length, "exit")
            }
        }
    }, [swiperSlide.isActive])

    useEffect(()=> {
        if (swiperSlide.standalone) restart()
    }, [])

    useEffect(() => {
        if (currentDialogIndex === -1 || currentDialogIndex >= dialogs.length) return;

        var dialog = dialogs[currentDialogIndex].dialog;
        const imgs = parseImageUrlsFromMarkdown(dialog);

        if (imgs.length === 1) {
            setBgSrc(imgs[0]);
            dialog = removeImageUrlsFromMarkdown(dialog);
        }

        setDisplayedText(dialog);
    }, [currentDialogIndex, dialogs]);

    const handleClick = (e: React.MouseEvent) => {

        const target = e.target as HTMLElement;
        if (target.tagName === 'IMG' || target.tagName === "A" || target.tagName === "BUTTON" || target.tagName === "I") return;

        if (currentDialogIndex < dialogs.length) {
            setCurrentDialogIndex((prevIndex) => prevIndex + 1);
        } else if (currentDialogIndex == dialogs.length) {
            setCompleted(true)
            restart()
        }
    };

    const restart = () => {
        setCurrentDialogIndex(0);
        setDisplayedText(getFirstDialog(dialogs));
        setBgSrc(character.portrait_url)

        /*const audio = (audioRef as React.RefObject<HTMLAudioElement>).current
        console.log(audioRef.current)
        const src = getBGMURL(content["bgm"])
        if (!src) {
            audio?.removeAttribute("src")
        } else {
            audio.src =src
        }
        if (audio) {
            audio.pause()
            audio.load()
            if (audio.src) {
                audio.play().catch(e=>console.log(e))
            }
        }*/
    };

    const getColorForName = (name: string) => {
        return colors[names.indexOf(name) % colors.length];
    };

    return (
        <div className="relative flex flex-col h-full justify-center items-center">
            <div className="w-full h-full flex justify-center items-center bg-gradient-to-t from-grad-100 via-grad-200 to-grad-300 z-[-1]">
                <div className="inset-0 w-full h-full overflow-hidden">
                    <img
                        src={bgSrc}
                        alt="Background"
                        className="inset-0 w-full h-full object-cover zoom-animation"
                    />
                </div>
            </div>
            <div className="absolute flex flex-col justify-end h-full w-full bottom-8" onClick={handleClick}>
                <div className="flex flex-row items-end justify-between overflow-x-hidden ps-2">
                    <div className="flex flex-col justify-end overflow-x-hidden ps-2">
                        <div className="text-white text-lg bg-black bg-opacity-70 rounded-lg">
                            {currentDialogIndex < dialogs.length ? (
                                <div className={`p-3 ${getColorForName(dialogs[currentDialogIndex]?.name)}`}>
                                    {currentDialogIndex >= 0 && <span className={`p-1 text-sm rounded-lg`}>{dialogs[currentDialogIndex]?.name} </span>}
                                    <Markdown>{replaceSensitiveWords(displayedText)}</Markdown>
                                </div>
                            ) : (
                                <div className="p-4 text-center text-xl flex flex-row items-center justify-between">
                                    【结束】
                                </div>
                            )}
                        </div>
                        <div className="text-white text-lg p-2">
                            <Link to={`/profile/${content.user_id}`}>@{content.nickname}</Link>
                            <div className="line-clamp-2 text-sm">
                                {`${content.title || ""}\n\n${content.description || ""}`}
                            </div>
                        </div>
                    </div>
                    <UISection character={character} content={content} setContent={setContent} userLiked={liked} sendContentView={() => sendContentView(content.id, consumedUnit(), dialogs.length, "chat")} />
                </div>
            </div>
        </div>
    );
};

const useSwiperReactive = () => {
    const swiper = useSwiper();
  
    // State to force a rerender.
    const [, setSignal] = useState({});
    const forceRerender = () => setSignal({});
  
    useEffect(() => {
      if (swiper)
        swiper.on('slideChange', forceRerender);
    }, []);
  
    return swiper;
  };

export function ContentCard({ content_id, index, audioRef }) {
    const [character, setCharacter] = useState<Character | null>()
    const [content, setContent] = useState<any>(null)
    const [liked, setLiked] = useState<any>(false)
    const [loading, setLoading] = useState<any>(false)

    useEffect(()=>{
        console.log(audioRef.current)
    }, [audioRef.current])

    const fetchLiked = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/content/${content_id}/like`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json()
            setLiked(!!data["liked"])
        } catch (error) {
            console.log(error)
        }
    }

    const fetchContent = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/content/${content_id}`, {
                method: 'GET',
            });
            const data = await res.json();
            console.log(data)
            setContent(data)

            // fetch character
            if (data["character_id"]) {
                const character = await fetchCharacterAPI(data["character_id"])
                setCharacter(character)
            }
        } catch (error) {
            toast.error("获取失败")
            return
        }
    }

    const init = () => {
        if (!loading) {
            setLoading(true)
            fetchLiked().then(()=>fetchContent()).then(()=>setLoading(false))
        }
    }

    const swiper = useSwiperReactive()
    useEffect(() => {
        if (swiper) {
            if ( !content && Math.abs(swiper.activeIndex - index) <= 1) {
                init()
            }
        } else {
            init()
        }
    }, [swiper?.activeIndex]);

    if (!content) {
        return <LoadingPage />
    }

    return (
        <div className="h-full w-full flex justify-center items-center">
            <div className="h-full w-full md:w-1/2">
                {character && <ContentSection content={content} setContent={setContent} character={character} liked={liked} audioRef={audioRef}/>}
            </div>
        </div>
    )

}

export function ContentPage() {

    const { content_id } = useParams()
    const { user } = useUser()
    const navigate = useNavigate()
    const audioRef = useRef<HTMLAudioElement>(null)

    const navEnd = () => (
        <>
            {user && user.group === "TopicAuthor" &&
                <button className="btn rounded-full btn-error" onClick={() => navigate(`mod`)}>
                    MOD
                </button>
            }
        </>
    )

    return (
        <NavbarContainer title="" navEnd={navEnd} hasColor={false} hasWrap={false} hasBg={false}>
            <audio ref={audioRef} loop id="content-page-audio"/>
            <div className="flex flex-col justify-center items-center h-screen w-full">
                <ContentCard content_id={content_id} index={0} audioRef={audioRef}/>
            </div>
        </NavbarContainer>
    )
}

export default ContentPage;