import React from "react";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
    return (
        <div className="flex flex-col bg-gradient-to-t from-grad-100 via-grad-200 to-grad-300 h-screen items-center justify-center gap-4">
            <p className="text-xl">当前页面不存在</p>
            <Link className="text-secondary underline" to="/">返回首页</Link>
        </div>
    )
}