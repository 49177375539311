import './ChatPage.css';
import useVoiceBot from './useVoiceBot.ts';
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast"
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { dateToText } from '../utils/date.js';
import LoadingPage from '../LoadingPage.tsx';
import 'react-medium-image-zoom/dist/styles.css'
import Markdown from '../common/markdown.tsx';
import NewTopicButton from './NewTopicButton.tsx';
import TextareaAutosize from 'react-textarea-autosize';
import ChatBubble from '../common/ChatBubble.tsx';
import {model_list, model_price_string, model_table} from './modelList.ts';
import { useUser } from '../context/UserContext.tsx';
import { getChatBackgroundKey } from './ChatBackgroundSelection.tsx';


function ChatPage() {

    const { charID } = useParams()

    const [searchParams, setSearchParams] = useSearchParams();
    const modelModalRef = useRef<HTMLDialogElement | null>(null);
    const otherOptsDialogRef = useRef<HTMLDialogElement | null>(null);
    const [confirmRestart, setConfirmRestart] = useState(false);
    const [checkedState, setCheckedState] = useState<boolean[]>([]);
    const {user} = useUser()

    const navigate = useNavigate()

    const {
        processing,
        character,
        history,
        userText,
        cookAIText,
        deleteEvent,
        regenEvent,
        editEvent,
        clearHistory,
        voiceOn,
        setVoiceOn,
        needFund,
    } = useVoiceBot(charID || "")

    const historyEndRef = useRef<null | HTMLDivElement>(null)
    const [lessText, setLessText] = useState(false);

    const [model, setModel] = useState(6);
    const [shareMode, setShareMode] = useState(false);

    useEffect(() => {
        if (!character) return
        const savedModel = sessionStorage.getItem('model-' + charID); // saved model is per character
        if (savedModel) {
            setModel(JSON.parse(savedModel))
            return
        }
        if (character.llm_model) {
            setModel(character.llm_model)
            return
        }
    }, [character])
    
    useEffect(() => {
        if (!character) {
            return
        }
        sessionStorage.setItem('model-' + charID, JSON.stringify(model));
    }, [model]);

    const scrollToBottom = () => {
        historyEndRef.current?.scrollIntoView()
    }

    useEffect(() => {
        if (history?.length) {
            scrollToBottom()
        }
    }, [history]);
    
    const handleModelChange = (model) => {
        setModel(model)
        // handleClose(modelModalRef)
    };

    const send = (input) => {
        userText(input,  model)
    };

    if (character === null) {
        return <LoadingPage />
    }

    const back = () => {
        if (searchParams.has('back')) {
            const backURL = searchParams.get('back') || ""
            navigate(backURL)
            return
        }
        navigate(-1)
    }

    function showOtherOptsDialog() {
        if (otherOptsDialogRef && otherOptsDialogRef.current) otherOptsDialogRef.current.showModal()
    }

    function handleRestartCharacter(){
        if (!confirmRestart) {
            setConfirmRestart(true)
            return
        }
        if (character) {
            clearHistory(character?.id)
            if (otherOptsDialogRef && otherOptsDialogRef.current) otherOptsDialogRef.current.close()
        }
    }

    const startShare = () => {
        setLessText(false)
        setShareMode(true)
        setCheckedState(new Array(history?.length).fill(false))
        otherOptsDialogRef.current?.close()
    }

    const otherOptsDialog = () => {
        return (
            <dialog className="modal" ref={otherOptsDialogRef}>
                <div className="modal-box flex flex-col space-y-8">
                    <Link className="btn btn-outline btn-secondary" to={`/character/${character.id}`} >
                        <i className="fa-solid fa-eye" />
                        查看角色
                    </Link>
                    <button className="btn btn-outline btn-secondary" onClick={startShare} >
                        <i className="fa-solid fa-share-from-square" />
                        分享对话
                    </button>
                    <Link className="btn btn-outline btn-secondary" to={`/character/setting/${character.id}`} >
                        <i className="fa-solid fa-pencil" />
                        主控设定
                    </Link>
                    <Link className="btn btn-outline btn-secondary" to={`/c/${character.id}/bg`} >
                        <i className="fa-solid fa-image" />
                        聊天背景
                    </Link>
                    <Link className="btn btn-outline btn-secondary" to={`/character/history/${character.id}`} >
                        <i className="fa-solid fa-book" />
                        聊天记录
                    </Link>
                    <button onClick={handleRestartCharacter} className="btn btn-error">
                        <i className="fa-solid fa-power-off" />
                        {confirmRestart ? "再次确认重启角色": "重启角色"}
                    </button>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        )
    }

    const goToShare = ()=>{
        if (!history) return
        const selectedHistory = history.filter((item, index) => checkedState[index]);
        const value = JSON.stringify(selectedHistory.map((item) => ({
            name: item["role"] === "user"? user?.nickname: character.name,
            dialog: item["content"],
        })))

        navigate(`/content/create`, {
                state: {
                    "value": value,
                    "character_id": charID,
                    "content_id": null
                }
            }
        )
    }

    const shareNavbar = () => (
        <div className="navbar fixed top-0 w-full z-[999] bg-base-100">
            <div className="navbar-start">
                <button className="btn btn-ghost text-error" onClick={()=>setShareMode(false)}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div className="navbar-center gap-1 w-1/2 justify-center">
                <div className="text-xl line-clamp-1">选择对话分享</div>
            </div>
            <div className="navbar-end">
                <button className="btn btn-ghost text-success" onClick={goToShare} ><i className="fa-solid fa-check"></i></button>
            </div>
        </div>
    )

    const voiceIcon = () => (
        <label className="swap">

            {/* this hidden checkbox controls the state */}
            <input type="checkbox" checked={voiceOn} onChange={() => setVoiceOn(!voiceOn)} />

            {/* volume on icon */}
            <i className="fa fa-volume-high swap-on"></i>

            {/* volume off icon */}
            <i className="fa fa-volume-xmark swap-off"></i>
        </label>
    )

    const navbar = () => {
        return (
        <div className="navbar fixed top-0 w-full z-[999] bg-base-100">
            <div className="navbar-start">
                <button className="btn btn-ghost" onClick={back}>
                    <i className="fa-solid fa-chevron-left"></i>
                </button>
            </div>
            <div className="navbar-center gap-1 w-1/2 justify-center">
                <div className="text-xl line-clamp-1">{character.name}</div>
                <div className={`btn btn-xs ${model!=character.llm_model && "btn-accent"}`} onClick={()=>handleOpen(modelModalRef)}>{model_table[model].name}</div>
            </div>
            <div className="navbar-end">
                {/*
                    voiceIcon()
                */}
                <button className="btn btn-ghost" onClick={showOtherOptsDialog} ><i className="fa-solid fa-ellipsis"></i></button>
            </div>
        </div>
        )
    }

    const handleClose = (modalRef) => {
        if (modalRef.current) {
            modalRef.current.hideModal()
        }
      }
    
    const handleOpen = (modalRef) => {
        if (modalRef.current) {
            modalRef.current.showModal()
        }
    }

    const modelButton = (forModel: number) => (
        <div
            onClick={() => handleModelChange(forModel)}
            className={`btn btn-secondary flex justify-between items-center ${model != forModel && "btn-outline"}`}
        >
            <p className="">{model_table[forModel].name}: {model_table[forModel].description}</p>
            <p className="text-xs text-slate-400">{model_price_string(model_table[forModel].price)}</p>
        </div>
    )

    const selectModelDialog = () => {
        return (
            <dialog className="modal" ref={modelModalRef}>
                <div className="modal-box flex flex-col space-y-4">
                    <h3 className="font-bold text-lg">选择一个AI模型</h3>
                    {
                        model_list.map((model)=>modelButton(model))
                    }
                    <div className="flex flex-row space-x-2 items-center">
                        <b>作者定价</b>
                        <i className="fa-solid fa-coins"></i>
                        <p>{Number(character.inspiration_price) > 0 ? `${character.inspiration_price} / 消息`: "免费" }</p>
                    </div>
                    <Link to="/me/coin" className="underline text-secondary">查看余额</Link>
                    <div className="modal-action">
                        <form method="dialog" className="space-x-4">
                            <button className="btn">完成</button>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        )
    }

    const bio = ()=> (
        <div className="p-4">
            <div className="p-3 rounded-xl bg-slate-200">
                <Markdown>{`**简介** ${character.bio}`}</Markdown>
            </div>
        </div>
    )

    const onBgClick = (e)=>{
        if (shareMode) {
            return
        }

        const tagName = e.target.tagName.toLowerCase(); // Normalizing tag name to lower case for comparison

        if (tagName === "div") {
            setLessText(!lessText)
        }
    }
    
    const goToCoin = ()=>(
        <div className="flex justify-center"><div className="text-center bg-base-100 p-4 rounded-lg">尝试 <Link to="" onClick={()=>handleOpen(modelModalRef)} className="text-secondary underline">切换模型</Link>，或者前往 <Link to="/me/coin" className="text-secondary underline">我的钱包</Link></div></div>
    )

    const handleCheckboxChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
    };

    const getBackgroundImage = () => {
        const savedBackground = localStorage.getItem(getChatBackgroundKey(character.id));
        if (savedBackground) {
            return savedBackground
        }
        return character.portrait_url
    }

    return (
        <div className={`flex flex-col relative h-screen`}>
            {!lessText && !shareMode && navbar()}
            {shareMode && shareNavbar()}
            <div className="fixed z-[-1] w-full h-full flex justify-center items-center bg-gradient-to-t from-grad-100 via-grad-200 to-grad-300" >
                {!shareMode && <img
                    src={getBackgroundImage()}
                    alt="Background"
                    className="inset-0 w-full md:w-1/2 h-full object-cover"
                />}
            </div>
            <div 
                onClick={onBgClick}
                className={`chat-container flex flex-col justify-end h-full w-screen ${lessText && "fading-div"}`}
            >
                <div className={` w-screen md:w-1/2 self-center overflow-y-auto overflow-x-hidden`}>
                    <div className="pt-[64px] pb-20">
                        {bio()}
                        {history && history.map((entry, index, arr) => (
                            <div className={shareMode ? "flex flex-row items-center": ""}>
                                {shareMode && !entry["hide"] && <input
                                    type="checkbox"
                                    className="checkbox m-4 checkbox-lg checkbox-success rounded-full"
                                    checked={checkedState[index]}
                                    onChange={() => handleCheckboxChange(index)}
                                />}
                                <ChatEntry key={index}
                                    entry={entry} prevEntry={arr[index - 1]}
                                    character={character}
                                    deleteEvent={async ()=>await deleteEvent(entry["id"])}
                                    regenEvent={async ()=>await regenEvent(character.id, entry["id"], model)} 
                                    editEvent={async (c: string)=>await editEvent(entry["id"], c)}
                                    isLast={index == history.length-1}
                                    shareMode={shareMode}
                                    startShare={startShare}
                                />
                            </div>
                        ))}
                        {(processing) ? <LoadingBubble character={character} /> : null}
                        {needFund && goToCoin()}
                        <div ref={historyEndRef} />
                    </div>
                </div>
            </div>
            {!shareMode && <div className={`fixed bottom-0 w-full z-[999] bg-base-100 flex-row flex w-full md:w-1/2 self-center justify-center items-center p-4 space-x-4`}>
                <ChatInput send={send} processing={processing} placeholder="发送消息">
                    <NewTopicButton character={character} cookAIText={cookAIText} processing={processing} />
                </ChatInput>

            </div>}
            {selectModelDialog()}
            {otherOptsDialog()}
        </div>
    );
}

export function ChatInput({send, processing, placeholder, children}) {
    const [input, setInput] = useState("");

    const textAreaRef = useRef<null | HTMLTextAreaElement>(null)
    const enterSend  = (()=>{
        const savedEnterSend = localStorage.getItem('enterSend');
        return savedEnterSend !== null ? JSON.parse(savedEnterSend) : true;
    })()

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (processing || !enterSend) {
            return
        }
        if (e.keyCode === 13 && !e.shiftKey && input.trim() !== '') {
            e.preventDefault()
            setInput("")
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            if (isMobile) {
                textAreaRef.current?.blur();
            }
            send(input)
        }
    }

    const sendWrapper = () => {
        if (!processing && input) {
            send(input)
            setInput("")
        }
    }

    return (
        <>
            <TextareaAutosize placeholder={placeholder} className="textarea textarea-bordered resize-none grow"
                maxRows={5}
                value={input}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                ref={textAreaRef}
            />
            {input && <button className="btn rounded-full" onClick={sendWrapper} disabled={processing}>
                <i className="fa-regular fa-paper-plane"></i>
            </button>}
            {!input && children}
        </>
    )
}

function LoadingBubble(props) {
    return (
        <div className={`chat chat-start px-4 py-2`}>
            <div className={`break-words max-w-full chat-bubble chat-bubble-primary`}>
                <span className="loading loading-dots loading-sm"></span>
            </div>
        </div>
    )
}

function GoToBroadcast({topic_id}) {
    return (
        <Link className={`btn btn-white btn-sm`} to={`/broadcasts/topics/${topic_id}`}>
            <i className={`fa fa-circle-right`} />
        </Link>
    )
}

function DeleteEvent({ onDelete }) {
  
    async function onDeleteWrapper() {
      await onDelete()
      toast.success("删除成功")
    }
  
    return (
        <button className="btn btn-sm" onClick={onDeleteWrapper}>
            <i className="fa fa-trash-can"></i>
        </button>
    );
}

function RegenEvent({ onRegen }) {
  
    return (
        <button className="btn btn-sm" onClick={onRegen}>
            <i className="fa-solid fa-arrow-rotate-left"></i>
        </button>
    );
}


function EditEvent({ content, onEdit }) {

    const editDialogRef = useRef<HTMLDialogElement | null>(null)
    const [input, setInput] = useState<string>(content)
    const [loading, setLoading] = useState(false)

    const showModal = () => {
        if (editDialogRef.current) {
            editDialogRef.current.showModal()
        }
    }

    const onEditWrapper = async () => {
        if (loading) {
            return
        }
        setLoading(true)
        await onEdit(input)
        setLoading(false)
        editDialogRef.current?.close()
    }

    const editDialog = () => (
        <dialog className="modal" ref={editDialogRef}>
            <div className="modal-box flex flex-col gap-2">
                <h3 className="font-bold text-lg">编辑消息</h3>
                <TextareaAutosize placeholder="发送消息" className="textarea textarea-bordered resize-none grow"
                    maxRows={5}
                    value={input}
                    onChange={e => setInput(e.target.value)}
                />
                <div className="modal-action">
                    <form method="dialog" className="space-x-4">
                        <button className="btn" disabled={loading} onClick={onEditWrapper}>保存</button>
                    </form>
                </div>
            </div>
            <form method="dialog" className="modal-backdrop">
                <button>close</button>
            </form>
        </dialog>
    )
  
    return (
        <>
            {editDialog()}
            <button className="btn btn-sm" onClick={showModal}>
                <i className="fa-solid fa-edit"></i>
            </button>
        </>
    );
}

function ChatEntry(props) {
    const { entry, prevEntry, character, deleteEvent, regenEvent, editEvent, isLast, shareMode, startShare } = props
    const isAI = entry.role === "assistant"
    const isSystem = entry.role === "system"
    const showRating = entry.type === "broadcast"

    var hasLag = true
    if (prevEntry) {
        if (entry.date - prevEntry.date < 60 * 60 * 1000) {
            hasLag = false
        }
    }

    const rating = entry.rating

    const dateBadge = () => {
        if (!hasLag || !entry.date) {
            return null
        }
        return (
            <div className="flex justify-center items-center p-5">
                <div className="badge badge-ghost">
                    {dateToText(entry.date)}
                </div>
            </div>
        )
    }

    if (entry["hide"]) {
        return null
    }
    
    return (
        <div>
            <div className="px-4 py-4">
            {!shareMode && dateBadge()}
            <ChatBubble role={entry["role"]} content={entry["content"]} />
            {!shareMode && <div className="px-8 flex flex-row space-x-4">
                {isAI && isLast && entry.id && !entry["broadcast_id"] && <RegenEvent onRegen={regenEvent} />}
                {(isAI || isSystem) && isLast && entry.id && !entry["broadcast_id"] && <EditEvent onEdit={editEvent} content={entry["content"]}/>}
                {(isAI || isSystem) && isLast && entry.id && <DeleteEvent onDelete={deleteEvent} />}
                {(isAI || isSystem) && entry["broadcast_id"] && <GoToBroadcast topic_id={entry["broadcast_id"]} />}
                {(isAI || isSystem) && isLast && <button className="btn btn-sm" onClick={startShare}>
                    <i className="fa-solid fa-share-from-square"></i>
                </button>}
            </div>}
            </div>
        </div>
    )
}

export default ChatPage;
