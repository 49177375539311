import React from "react";

// Define a type for the props
interface BiliBiliPlayerProps {
  bv: string;
}

const BiliBiliPlayer: React.FC<BiliBiliPlayerProps> = React.memo(({ bv }) => {
  return (
    <div className="relative" style={{ padding: "30% 45%" }}>
      <iframe
        className="absolute w-full h-full left-0 top-0"
        src={`https://www.bilibili.com/blackboard/html5mobileplayer.html?bvid=${bv}&page=1&as_wide=1&high_quality=1&danmaku=0`}
        scrolling="no"
        border="0"
        frameBorder="no"
        frameSpacing="0"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
});

export default BiliBiliPlayer;
