export interface Character {
    id: string
    name: string
    author_user_id: number
    author_username: string
    author_nickname: string
    gender: string
    avatar_url: string
    portrait_url: string
    appearance: string
    extra_prompt: string
    bio: string
    greeting: string
    public: boolean
    count: number
    voice: string
    llm_model: number
    inspiration_price: string
    comments: number
}

export const EmptyCharacter = {
    id: "",
    name: "",
    author_user_id: 0,
    author_username: "",
    gender: "",
    avatar_url: "",
    portrait_url: "",
    appearance: "",
    extra_prompt: "",
    bio: "",
    greeting: "",
    public: false,
    count: 0,
    voice: "",
    llm_model: 0,
    inspiration_price: "",
};

export interface User {
    id: number
    username: string
    nickname: string
    group: string
    email: string
    coin: number
    inspiration_coin: number
}

export type Broadcast = {
    id: number;
    source_id: number;
    title: string;
    content: string
    greeting: string
    author_username: string;
    author_nickname: string;
    count: number;
    character_name: string;
    character_avatar: string;
    public: boolean
    sample_image: string
    character_id: string
    time_created: string
    hide_content: boolean
};

export type SKU = {
    id: string;
    name: string;
    price: number;
    currency: string;
    description: string
}

interface GroupChatMember {
    id: string
    name: string
    avatar_url: string
    portrait_url: string
}

export interface GroupChat {
    id: string
    name: string
    members: GroupChatMember[]
}
