import React, { useEffect, useState } from "react";
import NavbarContainer from "./NavbarContainer.tsx";
import { Link } from "react-router-dom";
import { useUser } from "./context/UserContext.tsx";
import Cookies from "js-cookie"
import toast from "react-hot-toast";

function EmailVerifier() {
    const [email, setEmail] = useState()
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)

    const validateEmail = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/user/register/email`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`, // Add your authentication token here if needed
              },
              body: JSON.stringify({
                email: email,
              }),
            });
      
            if (response.ok) {
              const data = await response.json();
              toast("验证邮件已发送")
              setLoading(false)
              setSent(true)
              return
            } else {
              const errorData = await response.json();
              throw new Error(errorData.detail || "Failed to register email");
            }
          } catch (error: any) {
            console.log(error)
            setLoading(false)
            toast.error("无法发送邮件")
          }
    }

    return (
        <div className="flex flex-row w-full max-w-md gap-2">
            <input type="text" placeholder="邮箱地址" maxLength={30} value={email}
                onChange={(e)=>setEmail(e.target.value)} className="input input-bordered" 
            />
            <button className="btn" onClick={validateEmail} disabled={loading || sent}>
                {sent ?"已发送": "验证"}
            </button>
        </div>
    )
}

export default function SettingPage() {

    const {user, fetchUserAsync} = useUser()

    const [enterSend, setEnterSend] = useState(() => {
        const savedEnterSend = localStorage.getItem('enterSend');
        return savedEnterSend !== null ? JSON.parse(savedEnterSend) : true;
    });

    useEffect(() => {
        localStorage.setItem('enterSend', JSON.stringify(enterSend));
    }, [enterSend]);

    return (
        <NavbarContainer title="设置">
            <div className="p-4 gap-12 flex flex-col">
                <div className="flex flex-col gap-2">
                    <div>绑定邮箱：</div>
                    {!user?.email && <EmailVerifier />}
                    {user?.email && user.email}
                </div>
                <div className="flex flex-row gap-2">
                    <div>回车键发送消息</div>
                    <input
                        type="checkbox"
                        className="toggle"
                        checked={enterSend}
                        onChange={() => setEnterSend(!enterSend)}
                    />
                </div>
                <div>
                    遇到问题？请
                    <Link to="https://bwbbsvqza3t.feishu.cn/wiki/XqvawsQ0niZcC9kyUPccel35n7c" className="text-underline underline text-secondary px-2" target="_blank" >
                        联系我们
                    </Link>
                </div>

            </div>
        </NavbarContainer>
    )
}