import React, { ChangeEventHandler, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NavbarContainer from '../NavbarContainer.tsx';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import QuestionEntry from '../common/QuestionEntry.tsx';
import { uploadResource } from '../utils/api.ts';
import { replaceSensitiveWords } from '../utils/censor/censor.ts';

const questions = [
    {
        id: 'title',
        label: '标题',
        placeHolder: '请输入完整的帖子标题',
        maxLen: '30',
        textArea: false,
    },
    {
        id: 'description',
        label: '正文',
        placeHolder: '请输入正文',
        maxLen: '2000',
        textArea: true,
    },
]

const CreateImageTextContentPage = () => {

    const { state } = useLocation();
    const [images, setImages] = useState([]);

    const [choices, setChoices] = useState({
        "title": "",
        "description": "",
    })
    const [submitting, setSubmitting] = useState(false);

    const navigate = useNavigate()

    const handleSubmit = async () => {
        if (submitting) return
        setSubmitting(true)
        const description = choices["description"]
        const replaced = replaceSensitiveWords(description)
        if (replaced != description) {
            toast("存在违规内容")
            setSubmitting(false)
            return
        }

        try {
            const imageUploadPromises = images.map(image => uploadResource(image.file, ()=>{}));
            const imageUrls = await Promise.all(imageUploadPromises);
            console.log('Image URLs:', imageUrls);

            // Here you can send the form data and image URLs to your backend
            const res = await fetch(`${process.env.REACT_APP_API_URL}/content`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
                body: JSON.stringify({
                    "title": choices["title"],
                    "description": choices["description"],
                    "content": JSON.stringify(imageUrls),
                })
            });
            if (res.status !== 200) {
                toast.error("发布失败🙅");
                setSubmitting(false)
                return
            }
    
            const data = await res.json();
            const id = data["id"]
            navigate(`/content/it/${id}`, {replace: true})
        } catch (error) {
            console.error('Error uploading images:', error);
        } finally {
            setSubmitting(false);
        }
    }

    const handleImageChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const files = Array.from(e.target.files || []).slice(0, 3); // Limit to 3 files
        const newImages = files.map(file => ({
            file,
            preview: URL.createObjectURL(file)
        }));
        setImages(newImages);
    };

    const removeImage = (index) => {
        setImages(images.filter((_, i) => i !== index));
    };


    return (
        <NavbarContainer title="">
            <div className="flex flex-col gap-2 m-4 lg:w-1/2">
                {
                    questions.map((question, index) => (
                        <QuestionEntry key={index} question={question} choices={choices} setChoices={setChoices} />
                    ))
                }
                {images.length > 0 && (
                    <div className="mt-4 grid grid-cols-3 gap-4">
                        {images.map((image, index) => (
                            <div key={index} className="relative">
                                <img src={image.preview} alt={`preview ${index}`} className="w-full h-auto rounded-lg" />
                                <button
                                    type="button"
                                    onClick={() => removeImage(index)}
                                    className="absolute top-0 right-0 m-2 bg-red-500 text-white p-1 rounded-full"
                                >
                                    &times;
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                <label htmlFor="portrait-upload" className="btn btn-secondary self-start mt-4"> 添加图片(0-3)</label>
                <input
                    id="portrait-upload"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImageChange}
                    className="file-input file-input-bordered"
                    hidden
                />
                <button className="btn btn-primary self-start mt-8 max-w-md w-full"
                    disabled={!choices["title"] || !choices["description"] || submitting}
                    onClick={handleSubmit}
                >
                    发布
                </button>
            </div>
        </NavbarContainer>
    );
};

export default CreateImageTextContentPage