import React, { useEffect, useState } from "react";
import ImageSelection from "../common/BackgroundSelection.tsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import NavbarContainer from "../NavbarContainer.tsx";
import toast from "react-hot-toast";

export const getChatBackgroundKey = (charID: string) => (
    `background_${charID}`
)

export default function ChatBackgroundSelection() {
    const { charID } = useParams();
    const navigate = useNavigate();
    const [selected, setSelected] = useState<string>("");

    useEffect(() => {
        if (!charID) {
            return
        }
        // Load the selected background from local storage on component mount
        const savedBackground = localStorage.getItem(getChatBackgroundKey(charID));
        if (savedBackground) {
            setSelected(savedBackground);
        }
    }, [charID]);

    const onSave = () => {
        if (!charID) {
            return
        }
        // Save the selected background to local storage
        localStorage.setItem(getChatBackgroundKey(charID), selected);
        toast.success("修改成功！")
        navigate(-1);
    };

    const navEnd = () => (
        <>
            <button className="btn btn-ghost" onClick={onSave}>
                保存
            </button>
        </>
    );

    return (
        <NavbarContainer title="聊天背景" navEnd={navEnd}> 
            <div className="p-2 rounded-lg m-2 flex">ℹ️ 可选背景为角色立绘、公开话题中的图片</div>
            { charID && <Link to={`/broadcasts/create?character_id=${encodeURIComponent(charID)}`}  className="mx-4 btn btn-accent btn-sm">创建话题</Link>}
            <div className="p-2">
                <ImageSelection character_id={charID} selected={selected} setSelected={setSelected} />
            </div>
        </NavbarContainer>
    );
}