import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function EmailVerificationPage() {
  const [status, setStatus] = useState('loading');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const verifyEmail = async () => {
      const token = searchParams.get('token');

      if (!token) {
        setStatus('error');
        return;
      }

      try {
        // Replace with your actual API endpoint
        const res = await fetch(`${process.env.REACT_APP_API_URL}/user/email/verify?token=${token}`);

        if (res.status === 200) {
          setStatus('verified');
        } else {
          setStatus('error');
        }
      } catch (error) {
        setStatus('error');
      }
    };

    verifyEmail();
  }, [searchParams]);

  return (
    <div className="flex items-center justify-center h-scree">
      <div className="p-8 text-center card">
        {status === 'loading' && (
          <div className="flex items-center justify-center space-x-2 animate-bounce">
            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
          </div>
        )}

        {status === 'verified' && (
          <div className="text-center">
            <h2 className="mt-4 text-2xl font-bold text-green-300">验证成功</h2>
            <p className="mt-2 text-slate-400">
              你现在可以回到次元通讯了
            </p>
          </div>
        )}

        {status === 'error' && (
          <div className="text-center">
            <h2 className="mt-4 text-2xl font-bold text-red-300">验证失败</h2>
            <p className="mt-2 text-slate-400">
              过期或无效的链接
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
