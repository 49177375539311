import { parseImageUrlsFromMarkdown } from "./markdown.ts"
import { Broadcast } from "./type"

export const prettifyContent = (topic) => {
    return (topic["content"] as string).replace(/::.*\[[^\]]*\]{[^\}]+}/g, '').replace(/!?\[([^\]]*)\]\(([^)]+)\)/g, "").trim()
}

export const sample_image_url = (broadcast: Broadcast) => {
    var urls = parseImageUrlsFromMarkdown(broadcast.greeting)
    if (urls && urls.length) {
        if (urls[0].startsWith("BV")){
            return "https://rr-cn-resource-bucket.s3.cn-northwest-1.amazonaws.com.cn/system/video_cover.webp"
        }
        return urls[0]
    }

    var urls = parseImageUrlsFromMarkdown(broadcast.content)
    if (urls && urls.length) {
        if (urls[0].startsWith("BV")){
            return "https://rr-cn-resource-bucket.s3.cn-northwest-1.amazonaws.com.cn/system/video_cover.webp"
        }
        return urls[0]
    }
    
    return broadcast.character_avatar
}