import React, { useEffect, useState } from "react";
import NavbarContainer from "../NavbarContainer.tsx";
import { useParams } from "react-router-dom";
import ChatBubble from "../common/ChatBubble.tsx";
import Cookies from 'js-cookie';

export default function ChatHistoryPage() {

    const {charID} = useParams()
    const [history, setHistory] = useState<Array<Object> | null>(null);

    useEffect(()=>{
        initializeHistory()
    }, [])

    const initializeHistory = async () => {
        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/${charID}/history?limit=10000`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`,
            },
          });
    
          const data = await res.json();
          if (data.constructor === Array) {
            setHistory(data)
          }
          // connectWS() disable websocket for now
        } catch (e) {
          console.error(e);
        }
      };

    return (
        <NavbarContainer title="历史记录">
            <div className={` w-screen md:w-1/2 self-center overflow-y-auto overflow-x-hidden`}>
                <div className="pt-[64px] pb-20 px-4 space-y-4">
                    {history && history.map((entry) => (
                        <ChatBubble role={entry["role"]} content={entry["content"]}/>
                    ))}
                </div>
            </div>
        </NavbarContainer>
    )
}